import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FollowUpModel } from '../../model/FollowUpModel';
import { GroupModel } from '../../model/GroupModel';
import { IncidentNotificationModel } from '../../model/IncidentNotificationModel';
import { IncidentReportModel } from '../../model/IncidentReportModel';
import { JSAModel } from '../../model/JSAModel';
import { OIModel } from '../../model/OIModel';
import { PersonalSafetyModel } from '../../model/PersonalSafetyModel';
import { ProjectModel } from '../../model/ProjectModel';
import { UserModel } from '../../model/UserModel'; 
import { Async } from './Async';
import { ErrorPage } from './ErrorPage';
import './listPage.scss';
import { Loader } from './Loader';
import { Title } from './Title';
import { IHistory, IForms } from '../../interfaces';
import { closeCallListProps } from '../../model/MechModel';  
import { setFilters } from '../../utils/generalUtils'; 
declare var require: any;
const queryString = require('query-string');

type ModelInstance = GroupModel | ProjectModel | OIModel | UserModel | FollowUpModel |
    IncidentNotificationModel | JSAModel | IncidentReportModel | PersonalSafetyModel | closeCallListProps | any;

export interface IListPageProps {
    pageHeading: string;
    style?: React.CSSProperties;
    instances: ModelInstance[];
    titleStyle?: React.CSSProperties;
    rowHeadings: string[];
    rowSortings?: {};
    listItemComponent: React.ComponentClass<{ instance?: ModelInstance, index?: number, style?: React.CSSProperties, currentPage?: number, inputParam1?:string}>; 
    emptyInstancesMessage?: string;
    isSearchable?: boolean;
    searchableBy?: string[];
    searchPlaceHolder?: string;
    customSearchComponent?: JSX.Element;
    sm?: number[];
    customHeadings?: JSX.Element;
    searchFrom?: ModelInstance[];
    currentPage?:number;
    inputParam1?:string; 
    history?: IHistory; 
    filterComponent?: JSX.Element;
    promise?: (sortIndex: any) => void; 
    forms?: IForms;
}

export interface IListPageState {
    searchText: string;
    showSearchInput: boolean;
    isLoading: boolean;
}

export class ListPage extends React.Component<IListPageProps, IListPageState> {
    constructor(props: IListPageProps | Readonly<IListPageProps>) {
        super(props);
        this.state = { searchText: '', showSearchInput: false, isLoading: false, };
    } 
    filteredInstances = []; 
    promise = async () => {
       /*  if (UserModel.list().length <= 1) {
            await getAllGroups();
        }
        if (ProjectModel.list().length <= 0) {
            await getAllProjects();
        }
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        } */
        return;
    }

    renderListHeadings = () => {
        const { rowHeadings, sm, rowSortings, history } = this.props;
        let urlParams: any = queryString.parse(history?.location['search']);
        let currentSortingIndex = urlParams.sortingIndex?.toString() || '';
        let currentSortingOrder = urlParams.sortingOrder || '';
        let sortingKeys: any = (rowSortings && Object.keys(rowSortings)) || false; 
        return rowHeadings.map((heading, index) => { 
            //let applySorting = sortingKeys && sortingKeys.includes(index.toString())?true:false;
            let applySorting = sortingKeys && sortingKeys.includes(index.toString())?(currentSortingIndex===index.toString() ?currentSortingOrder: 'givOp' ):false; 
            return <Col key={index} sm={sm && sm[index]}>{heading} {applySorting ? (applySorting==='asc'? <i className="fa fa-solid fa-caret-down " onClick={() => this.sortListing(index)}></i>:(applySorting==='desc'?<i className="fa fa-solid fa-caret-up " onClick={() => this.sortListing(index)}></i>:<i className="fa fa-solid fa-sort" onClick={() => this.sortListing(index)}></i>)):''}</Col>;
        });
    }

    sortListing = async (index) => { 
        const { promise, history, forms  } = this.props;  
        let urlParams = queryString.parse(history?.location['search']);
        let param: any = { pageNumber: 1, activeBar: 1, sortingIndex: index, sortingOrder: '' };
        if(urlParams && forms) { 
            let sortingOrder  = {sortingOrder: urlParams.sortingOrder && urlParams.sortingOrder==='desc' && index.toString()===urlParams.sortingIndex ?'asc':'desc', searchItem: urlParams.searchItem};
            param = { ...param, ...sortingOrder, ...forms.commonFilterForm }
        }  
        history && setFilters(param, history);
        if (promise) {
            await promise(index);
        }
        return;
    }

    renderListItems = () => {
        const { props: { listItemComponent, emptyInstancesMessage, instances} } = this;
        if (!instances || instances.length <= 0) {
            return (
                <div style={{ textAlign: 'center', padding: '26px', color: 'white' }}>{emptyInstancesMessage}</div>
            );
        }
        const ListItem = listItemComponent;
        let filteredInstances = instances;  
        /* if (isSearchable && searchText.length > 0 && searchableBy) {
            filteredInstances = [];
            (searchFrom || instances).forEach(instance => { 
                searchableBy.forEach(search => {
                    let searchProps = instance[search] || instance.props[search]
                    if (searchProps && searchProps.toString().toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
                        //if (instance.props[search] && instance.props[search].toString().toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
                        if (filteredInstances.indexOf(instance) >= 0) {
                            return;
                        }
                        filteredInstances.push(instance);
                        return;
                    }
                });
            });
        } */
        if (filteredInstances.length <= 0) {
            return (
                <div style={{ textAlign: 'center', padding: '26px', color: 'grey' }}>{emptyInstancesMessage}</div>
            );
        }
        let style: React.CSSProperties = {
            borderBottom: 'none'
        };
        const last = filteredInstances.length - 1;
        return filteredInstances.map((instance, index) => {
            return (
                <span key={index}>
                    <ListItem style={last === index ? style : {}} index={index} instance={instance} currentPage={this.props.currentPage} inputParam1={this.props.inputParam1}/>
                </span>
            );
        });
    }

    handleSearchInput = async (event: { target: { value: any; }; }) => { 
        let searchItem = event.target.value;
        this.setState({ searchText: searchItem }); 
        const { forms, history, promise } = this.props;
        this.setState({ isLoading: true, });
        let urlParams = queryString.parse(history?.location['search']);
        let param: any = { pageNumber: 1, activeBar: 1, searchItem: searchItem, sortingIndex: '', sortingOrder: '' }; 
        if(urlParams) {
            param = { ...param, ...forms?.commonFilterForm }
        } 
        history && setFilters(param, history);
        if (promise) {
            await promise('');
        } 
    }

    handleSearchButtonClick = () => {
        this.setState({ showSearchInput: true, });
    }
    clearSearchInput = async () => {
        const { forms, history, promise } = this.props;
        this.setState({ searchText: '', showSearchInput: false, isLoading: false, }); 
        let urlParams = queryString.parse(history?.location['search']);
        let param: any = { pageNumber: 1, activeBar: 1, searchItem: '', sortingIndex: '', sortingOrder: '' }; 
        if(urlParams) {
            param = { ...param, ...forms?.commonFilterForm }
        } 
        history && setFilters(param, history);
        if (promise) {
            await promise('');
        }
    } 

    renderSearch = () => {
        const { state: { showSearchInput, searchText, isLoading }, props: { searchPlaceHolder, isSearchable } } = this;
        if (!isSearchable) {
            return;
        }
        return (
            <div className="search">
                <div
                    onClick={this.handleSearchButtonClick}
                    className={showSearchInput ? 'search-button-hidden' : 'search-button__ search-button-hidden'}
                >
                    <i className="fa fa-search" aria-hidden="true"></i>
                    <span> &nbsp;Search</span>
                </div>
                <div className={showSearchInput ? 'search-box' : 'search-box-hidden__ search-box'}>
                    <i className="fa fa-search" aria-hidden="true"></i>
                   {/*  { isLoading && <i className="fa fa-circle-o-notch fa-spin"  ></i> }  */}
                    <input   
                        type="text" 
                        onChange={this.handleSearchInput}
                        value={this.state.searchText}
                        id="search"
                        placeholder={searchPlaceHolder || 'Start typing to search...'}
                        autoFocus={this.state.showSearchInput}
                        className="form-control"
                    />
                    {/* <RRFInput
                        id="search"
                        placeholder={searchPlaceHolder || 'Start typing to search...'}
                        onChange={this.handleSearchInput}
                        type="text"
                        model=".searchInput"
                        autofocus={true}
                        defaultValue={this.state.searchText}
                    /> */}
                    { searchText && <i className="fa fa-times" aria-hidden="true" onClick={this.clearSearchInput}></i> }
                </div>
            </div>
        );
    } 
    /* render() { 
        const { props: { pageHeading, style, titleStyle, customSearchComponent, filterComponent }
        } = this;
        return (
            <>
                <div style={style} className="list-page">
                    <Title style={titleStyle || {}} text={pageHeading}></Title> 
                    {filterComponent}
                    {customSearchComponent || this.renderSearch()}
                    <div className="reports-table">
                        {this.props.customHeadings || <Row className="heading">
                            <Col sm={1}>{pageHeading === 'Users' ? 'ID' : (pageHeading === 'Incident Investigative Reports' ? 'Form Id': '#')}</Col>
                            {this.renderListHeadings()}
                        </Row >}
                        <Async
                            identifier="reportList" 
                            loader={<Loader />}
                            error={<ErrorPage />}
                            content={this.renderListItems()}
                        /> 
                    </div>
                </div >
            </>
        ); 
    } */
    render() {
        const { props: { pageHeading, style, titleStyle, customSearchComponent, filterComponent }
        } = this;
        return (
            <div style={style} className="list-page">
                {pageHeading && <Title style={titleStyle || {}} text={pageHeading}></Title> }
                {filterComponent}
                {customSearchComponent || this.renderSearch()}
                <div className="reports-table">
                    {this.props.customHeadings || <Row className="heading">
                        <Col sm={1}>{pageHeading === 'Users' ? 'ID' : (pageHeading === 'Incident Investigative Reports' ? 'Form Id': '#')}</Col>
                        {this.renderListHeadings()}
                    </Row >}
                    <Async
                        identifier="AllListPage"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={ <> {this.renderListItems()} </>}
                    />
                </div>
            </div>
        );
    } 
}
