import * as React from 'react';
import { IHistory } from '../../interfaces';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Col, DropdownButton, Dropdown, Row, Button } from 'react-bootstrap'; 
import { withRouter } from 'react-router-dom';
import { SafetyWalkthroughModel } from '../../model/SafetyWalkthroughModel';
import { connect } from 'react-redux';
import { UserModel } from '../../model/UserModel';
import { showDelConfirmModal } from '../../actions/modalActions';  
import { setLoading} from '../../actions/loadingActions';  
import { getSWReportDetail } from '../../services/attributeService';
import { showCloseCallFollowUpListModal } from '../../actions/modalActions';   
import { getAllFollowUpForms } from '../../services/closeCallService'; 
import { ChecklistNameModel } from '../../model/ChecklistModel';
import { getPageItemNumber } from '../../utils/generalUtils'; 
const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: SafetyWalkthroughModel;
    style?: React.CSSProperties;
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {

    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps; 
    onGetSWReportDetsild = (item: any) => { 
        setLoading('swFormList');
        getSWReportDetail(item.formId, item.formType).then(() => { 
            let formType = item.formType.replace(/[^a-zA-Z]/g, "-"); 
            formType = formType.replace(/---/g, "-"); 
            this.props.history?.push(`/sw-report-detail/${item.formId}/${formType}`);
        });
    }
    onDeleteAttribute(instance: any, actionType: string) { 
        instance.id = instance.formId; 
        let formType = (instance.formType==='Supervisor Checklist' || instance.formType==='Manager Checklist')?'Safety Walk Form':'Checklist Form'; 
        showDelConfirmModal('delete-confirmation', formType, instance, actionType);  
    }
    onEditAttribute(instance: any) {  
        setLoading('swFormList');
        getSWReportDetail(instance.formId, instance.formType).then(async () => {  
            switch(instance.formType) {
                case 'Manager Checklist':
                    this.props.history?.push(`/safety-walkthrough/edit/${instance.formId}`)
                    break;
                case 'Supervisor Checklist':
                    this.props.history?.push(`/supervisor-safetywalkthrough/edit/${instance.formId}`)
                    break;
                default: 
                    let checklistNameInstance = ChecklistNameModel.getBy('checklistName',instance.formType);   
                    this.props.history?.push(`/checklist-edit-form/${checklistNameInstance.props.key}/${instance.formId}`)
                    break;
            } 
        });
    }
    getFollowUpList(instance: any) {  
        if(!UserModel.checkUserAccess('follow_up_list') )
            return;
        let modalInstance: any = [];  
        modalInstance['closeCallId'] = instance['formId'];
        modalInstance['followUpFor'] = instance;
        showCloseCallFollowUpListModal(modalInstance); 
        /* let reportDetail: FollowUpParentDetails = {
            closeCallId: instance['formId'],
            process: '',
            requestFrom: 'saftyWalkthrough', 
        };
        dispatch(getFollowUpDetails(reportDetail));
        instance['closeCallId'] = instance['formId'];
        showCloseCallFollowUpListModal(instance); */
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['formId']);
    }
    renderFormList = () => {
        const { instance, index, style } = this.props; 
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        } 
        let formType = instance.formType==='Manager Checklist' || instance.formType==='Supervisor Checklist' || instance.formType==='Close Call Checklist' ? 'walkthrough':'other';
        return (
            <Row className="report-row" style={style} >
                <Col className="clickable-list-item" sm={1} >
                    <DropdownButton title="" id="bg-vertical-dropdown-3">
                        { UserModel.checkUserAccess('safety_walkthrough_view') && <MenuItem eventKey="1"
                            onClick={() => this.onGetSWReportDetsild(instance) }
                            className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                        { UserModel.checkUserAccess('safety_walkthrough_edit') && <MenuItem eventKey="2"
                            onClick={() => this.onEditAttribute(instance)}
                            className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                      
                        { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="3"
                            onClick={() => this.onDeleteAttribute(instance, 'archive')}
                            className="item"><i className="fa fa-archive" aria-hidden="true"></i> Archive</MenuItem> }
                        { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="4"
                            onClick={() => this.onDeleteAttribute(instance, 'delete')}
                            className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                    </DropdownButton> 
                    <span className="serial-no">{getPageItemNumber(index || 0, this.props.currentPage) }</span>
                </Col>
                <Col className="clickable-list-item" onClick={() => this.onGetSWReportDetsild(instance)} sm={formType==='walkthrough'?2:2} >
                    {instance.date || 'NA'}
                </Col>
                <Col className="clickable-list-item" onClick={() => this.onGetSWReportDetsild(instance)} sm={formType==='walkthrough'?3:2} >
                    {instance.userName || 'NA'}
                </Col>
                <Col className="clickable-list-item" onClick={() => this.onGetSWReportDetsild(instance)} sm={formType==='walkthrough'?3:2} >
                    {instance.formType || 'NA'}
                </Col>
                {formType==='other' &&  <Col className="clickable-list-item" onClick={() => this.onGetSWReportDetsild(instance)} sm={2} >
                    {instance.checklistTitle || 'NA'}
                </Col>}
                {formType==='other' &&  <Col className="clickable-list-item" onClick={() => this.onGetSWReportDetsild(instance)} sm={2} >
                    {instance.serialNo || 'NA'}
                </Col>}
                <Col className="clickable-list-item" sm={formType==='walkthrough'?3:1} >
                    { <Button className="closecall-followup-button" onClick={() => this.getFollowUpList(instance)} > { ( (instance.totalCloseCallCount || 0) - (instance.totalCloseCallOpenCount || 0) ) || '0'}/{instance.totalCloseCallCount || '0'}</Button> }
                </Col>
            </Row>
        );
    }

    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {
    return { 
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
