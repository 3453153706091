import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '../FormComponents/Button';
import { Form, actions } from 'react-redux-form';
import { connect } from 'react-redux'; 
import PropTypes from 'prop-types';
import { RRFInput } from '../FormComponents/RRFInput';
import { IForms } from '../../../interfaces';
import { dispatch, showAlert, setFilters } from '../../../utils/generalUtils'; 
import { getGroupsOfUser, getProjectsOfUser } from '../../../services/userService';
import { UserModel } from '../../../model/UserModel';
import { Alert } from '../../Alert'; 
import './commonFilter.scss';
import { Label } from '../FormComponents/Label';
import { Async } from '../Async';
import { Loader } from '../Loader'; 
import { ErrorPage } from '../ErrorPage';
import { ChecklistNameModel } from '../../../model/ChecklistModel';
import { getAllCheckListNames } from '../../../services/checklistService';
declare var require: any;
const queryString = require('query-string');

export interface ICommonFilterProps {
    onApply?: () => void; 
    defaultValue?: number; 
    userId: string;
    userInstance?: any;
    forms: IForms;
    history?: any;
    additionalFilters?: any;
}

export interface ICommonFilterState {
    teams: any;
    groups: any;
    checklistNames: any;
}
export class CommonFilterImpl extends React.PureComponent<ICommonFilterProps, ICommonFilterState> {
    constructor(props: ICommonFilterProps | Readonly<ICommonFilterProps>) {
        super(props);
        this.state = {teams: [], groups: [], checklistNames: [], };
    }

    async componentWillMount() { 
        dispatch(actions.reset('forms.commonFilterForm')); 
    }
    componentWillUnmount() {
        dispatch(actions.reset('forms.commonFilterForm'));
     }

    getChildContext() {
        return { formModel: 'forms.commonFilterForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };
    promise = async () => { 
        const { userInstance, userId, additionalFilters } = this.props;
        let teams = await getProjectsOfUser(userId, userInstance); 
        this.setState({teams: teams});
        if(additionalFilters ) {
            additionalFilters.forEach(async (data: string)=>{ 
                switch(data) {
                    case 'checklistNames':
                        if(ChecklistNameModel.list().length<1) {
                            await getAllCheckListNames(); 
                        } 
                        let checklists = ChecklistNameModel.list();
                        let options: any = [];
                        checklists.forEach(el => { 
                            options.push({ 'value': el.props.id, 'label': el.props.checklistName })
                        });  
                        this.setState({checklistNames: options});
                        break; 
                }
            });
        }
    }
    applyCommonFilter = async () => {
        const { onApply, forms, history } = this.props;
        if(forms.commonFilterForm.filterType==='yearly' && !forms.commonFilterForm.year) {
            showAlert('Please choose year', 'common-filter-form', 'danger'); return; 
        }
        if(forms.commonFilterForm.filterType==='monthly' && (!forms.commonFilterForm.month || !forms.commonFilterForm.year) ) {
            showAlert('Please choose month & year', 'common-filter-form', 'danger'); return;
        }
        if(forms.commonFilterForm.filterType==='yearly' || forms.commonFilterForm.filterType==='summary') {
            await dispatch(actions.change('forms.commonFilterForm.month', ''));
        } 
        await dispatch(actions.change('forms.commonFilterForm.isSubmitted', true));
        let urlParams = queryString.parse(history?.location['search']);
        let param: any = { pageNumber: 1, activeBar: 1, searchItem: urlParams?.searchItem || ''   }; 
        if(urlParams) {
            param = { ...param, ...forms.commonFilterForm }
        } 
        history && setFilters(param, history);
        if (onApply) {
            return onApply();
        }
    }

    clearFilter = async () => {
        const { onApply } = this.props;
       await dispatch(actions.reset('forms.commonFilterForm')); 
        dispatch(actions.change('forms.commonFilterForm.isSubmitted', false)); 
        if (onApply) { 
            return onApply();
        }
    }

    onChangeTeam = (value: string) => {
        dispatch(actions.change('forms.commonFilterForm.groups', '')); 
    }

    renderCommonFilter = () => {
        const { userId, forms, additionalFilters } = this.props; 
        let yearOptions:Array<any> = [];
        let startYear = new Date().getFullYear(); 
        let endYear = 2017;
        for (let i = startYear; i >= endYear; i--) {
            yearOptions.push({
                value: startYear.toString(), label: startYear
            });
            startYear--;
        }
        const monthOptions = [
            { value: '01', label: 'January' }, { value: '02', label: 'February' }, { value: '03', label: 'March' }, { value: '04', label: 'April' }, { value: '05', label: 'May' }, { value: '06', label: 'June' }, { value: '07', label: 'July' }, { value: '08', label: 'August' }, { value: '09', label: 'September' }, { value: '10', label: 'October' }, { value: '11', label: 'November' }, { value: '12', label: 'December' } ]; 
        const team = (forms as any)[`commonFilterForm`]['teams'] || '';  
        if(!userId || !forms || !this.state.teams ) return <></> 
       
        return (
            <Form model="forms.commonFilterForm" onSubmit={this.applyCommonFilter} className="commonFilter">
                <Row className="show-grid filter" style={{ padding: '10px 20px' }}>
                    <Col sm={3} className="input ">
                        <Label sm={12}>Teams </Label>
                        <RRFInput
                            type="dropdown" 
                            menuItems={this.state.teams}
                            model={`.teams`} 
                            id="teams"
                            multi={true}
                            defaultValue={forms.commonFilterForm.teams}
                            onSelect={this.onChangeTeam}
                        />
                    </Col>
                    <Col sm={3} className="input ">
                        <Label sm={12}>Groups </Label>
                        <RRFInput
                            type="dropdown" 
                            menuItems={this.props.userId && getGroupsOfUser(this.props.userId, team )}
                            model=".groups" 
                            id="groups"
                            multi={true}
                            defaultValue={forms.commonFilterForm.groups}
                        />
                    </Col>
                    { additionalFilters && additionalFilters.includes('checklistNames') &&
                        <Col sm={2} className="input ">
                            <Label sm={12}>Checklist Type</Label>
                            <RRFInput
                                type="dropdown" 
                                menuItems={this.state.checklistNames}
                                model={`.checklistName`} 
                                id="checklistName" 
                                defaultValue={forms.commonFilterForm.checklistName} 
                            />
                        </Col>
                    }
                    <Col sm={2} className="input" >
                        <Label sm={12}>Report Status </Label>
                        <RRFInput 
                            type="dropdown"
                            menuItems={[{ value: 'active', label: 'Active' },
                            { value: 'archive', label: 'Archive' } ]} 
                            model=".reportStatus"
                            defaultValue={forms.commonFilterForm.reportStatus || 'active'}
                        />
                    </Col> 
                    <Col sm={2} className="input" >
                        <Label sm={12}>Filter Type </Label>
                        <RRFInput 
                            type="dropdown"
                            menuItems={[{ value: 'summary', label: 'Cumulative' },
                            { value: 'yearly', label: 'Yearly' },
                            { value: 'monthly', label: 'Monthly' }]} 
                            model=".filterType"
                            defaultValue={forms.commonFilterForm.filterType || 'summary'}
                        />
                    </Col> 
                    { (forms.commonFilterForm.filterType === 'yearly' || forms.commonFilterForm.filterType === 'monthly' ) && 
                        <><Col sm={2} className="input">
                            <Label sm={12}>Year </Label>
                            <RRFInput
                                type="dropdown"
                                menuItems={yearOptions}
                                model=".year" 
                                defaultValue={forms.commonFilterForm.year}
                            />
                        </Col></>
                    }
                    { (forms.commonFilterForm.filterType === 'monthly') && 
                        <><Col sm={2} className="input">
                            <Label sm={12}>Month </Label>
                            <RRFInput
                                type="dropdown"
                                menuItems={monthOptions}
                                model=".month" 
                                defaultValue={forms.commonFilterForm.month}
                            />
                        </Col></>
                    }  
                    <Col sm={1} className="input done" style={{marginTop: '30px', marginLeft: '20px'}}>
                        <Button type="submit" style={{ width: '80%', color: 'green', border: 'green', }} >
                            <i className="fa fa-filter" aria-hidden="true"></i> Done
                        </Button>
                    </Col>
                    {forms.commonFilterForm.isSubmitted &&
                        <Col sm={1} className="input done" style={{marginTop: '30px', marginLeft: '20px'}}>
                            <Button type="button" style={{ width: '80%', color: 'red', border: 'red', }} onClick={ this.clearFilter } >
                                <i className="fa fa-refresh" aria-hidden="true"></i> Reset
                            </Button>
                        </Col>
                    }
                </Row> 
                <Alert className="danger-alert" id="common-filter-form" />
            </Form>
        );
    }
    render() {
        return <Async
            promise={this.promise}
            identifier="OIForm"
            loader={<Loader />}
            error={<ErrorPage />}
            content={this.renderCommonFilter()}
        />; 
    }
}
 
export function mapStateToProps( state: any ) { 
    const userId = state.login.get('userId'); 
    return { forms: state.forms, userId, userInstance: UserModel.get(userId) || UserModel.getUserByUID(userId) };
}

export const CommonFilter = connect<ICommonFilterProps, any, any>(mapStateToProps)(CommonFilterImpl);
