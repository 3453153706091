import * as React from 'react'; 
import { connect } from 'react-redux';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';  
import PropTypes from 'prop-types';
import { IForms, IHistory } from '../../../interfaces'; 
import { dispatch } from '../../../utils/generalUtils';
import { Alert } from '../../Alert';
import { AlertModal } from '../../reusableComponents/ModalComponents/AlertModal';
import { BasePage } from '../../reusableComponents/BasePage'; 
import './concerntypes.scss'; 
import { Async } from '../../reusableComponents/Async';
import { ErrorPage } from '../../reusableComponents/ErrorPage';
import { Loader } from '../../reusableComponents/Loader';
import { SubmissionLoader } from '../../reusableComponents/SubmissionLoader';
import { Form } from '../../reusableComponents/FormComponents/Form';
import { Title } from '../../reusableComponents/Title'; 
import { RRFInput } from '../../reusableComponents/FormComponents/RRFInput';
import { Button } from '../../reusableComponents/FormComponents/Button'; 
import { submitConcernTypesForm } from '../../../services/miscellaniousServices'; 
import { getConcernTypes } from '../../../services/oiformService';

export interface IConcernTypesFormProps { 
    history?: IHistory;
    forms: IForms; 
    concernTypes: any;
    userUID: string;
}

export interface IConcernTypesFormState { 
    concernTypes: {uniqueId: string; concern: string; activities: {uniqueId: string; activity: string; priority: number;}[]; priority: number; }[];
    deletedData: {concernType: any, activity: any}
}

export class ConcernTypesFormImpl extends React.PureComponent<IConcernTypesFormProps, IConcernTypesFormState> {

    constructor(props: IConcernTypesFormProps | Readonly<IConcernTypesFormProps>) {
        super(props);  
        this.state = { concernTypes: [{'uniqueId': '', 'concern': '', 'priority': 1,  'activities': [{'uniqueId': '', 'activity': '', 'priority': 1}] }], deletedData: {concernType: [], activity: [] } };
    } 
    
    promise = async () => { 
        await getConcernTypes();
        const { concernTypes } = this.props;
        await concernTypes.forEach((concern, index) => {   
            dispatch(actions.change(`forms.concernTypesForm.concern[${index}]`, concern.concern)); 
            dispatch(actions.change(`forms.concernTypesForm.priority[${index}]`, +concern.priority)); 
            dispatch(actions.change(`forms.concernTypesForm.uniqueId[${index}]`, concern.uniqueId)); 
            concern.activities.forEach((activity, activityIndex) => { 
                dispatch(actions.push(`forms.concernTypesForm.activities[${index}].activity`, activity.activity));
                dispatch(actions.push(`forms.concernTypesForm.activities[${index}].priority`, +activity.priority));
                dispatch(actions.push(`forms.concernTypesForm.activities[${index}].uniqueId`, activity.activityUniqueId));
            }) 
        });
        this.setState({concernTypes: [...concernTypes] });  
    }
    getChildContext() {
        return { formModel: 'forms.concernTypesForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    handleFormSubmit = async ({ concern, activities, priority, uniqueId }: any) => { 
        const { props: { forms, history, userUID } } = this;  
        let answerData: any = {concernTypes: [], userUID: userUID, deletedData: this.state.deletedData }; 
        /* if (isFormEmpty(answerData)) {
            showAlert(EMPTY_FORM_MESSAGE, 'concern-type-form-failed', 'danger');
            return;
        }  */ 
        (concern || []).forEach((element: any, index: any) => { 
            answerData.concernTypes.push({concern: element, priority: priority[index], uniqueId: uniqueId[index], activities: [] }); 
            if(activities[index] ) {
                (Object.keys(activities[index].activity) || []).forEach((concernActivity: any, activityIndex: any) => { 
                    answerData.concernTypes[index].activities.push({activity: activities[index].activity[activityIndex], priority: activities[index].priority[activityIndex], uniqueId: activities[index].uniqueId[activityIndex] })
                });
            }
        }); 
        return history && submitConcernTypesForm(answerData, history, forms); 
    } 
    incrementRows = (event: { preventDefault: () => void; }) => { 
        event.preventDefault();
        dispatch(actions.push(`forms.concernTypesForm.concern`, '')); 
        dispatch(actions.push(`forms.concernTypesForm.priority`, '')); 
        dispatch(actions.push(`forms.concernTypesForm.uniqueId`, '')); 
        dispatch(actions.push(`forms.concernTypesForm.activities`, '')); 
       /*  let length = this.state.concernTypes.length + 1;
        this.setState({
            concernTypes: [...this.state.concernTypes, { uniqueId: '', concern: '', priority: length, activities: [{uniqueId: '', activity: '', priority: 1}] }]
        }); */
    }
    incrementActivityRows = (index: number) => { 
        dispatch(actions.push(`forms.concernTypesForm.activities[${index}].activity`, '')); 
        dispatch(actions.push(`forms.concernTypesForm.activities[${index}].priority`, '')); 
        dispatch(actions.push(`forms.concernTypesForm.activities[${index}].uniqueId`, '')); 
    }
    deleteConcernTypeRows = (concernIndex: number ) => {
        const { state: { concernTypes }, props: { forms } } = this; 
        dispatch(actions.remove(`forms.concernTypesForm.concern`, concernIndex));
        dispatch(actions.remove(`forms.concernTypesForm.priority`, concernIndex));
        dispatch(actions.remove(`forms.concernTypesForm.uniqueId`, concernIndex));
        dispatch(actions.remove(`forms.concernTypesForm.activities`, concernIndex));
        let newArray = concernTypes.filter(e => e !== concernTypes[concernIndex]);
        this.setState({
            concernTypes: [...newArray]
        }); 
        this.state.deletedData?.concernType.push(forms.concernTypesForm.uniqueId[concernIndex])
         
    }
    deleteActivityRows = (concernIndex: number, activityIndex: number) => { 
        const { state: { concernTypes }, props: { forms } } = this; 
        dispatch(actions.remove(`forms.concernTypesForm.activities[${concernIndex}].activity`, activityIndex));
        dispatch(actions.remove(`forms.concernTypesForm.activities[${concernIndex}].priority`, activityIndex));
        dispatch(actions.remove(`forms.concernTypesForm.activities[${concernIndex}].uniqueId`, activityIndex));
        let newArray = concernTypes[concernIndex].activities.filter(e => e !== concernTypes[concernIndex].activities[activityIndex]);
        concernTypes[concernIndex].activities = newArray;
        this.setState({
            concernTypes:  [...concernTypes]
        }); 
        this.state.deletedData?.activity.push(forms.concernTypesForm.activities[concernIndex].uniqueId[activityIndex])
    }
    renderDynamicActivities = (concernIndex, activities) => {
        const { props: {forms} } = this; 
        if(!activities || activities.activity.length<1) {
            return '';
        } 
        return [<div className='activites-title'>Activities of {forms.concernTypesForm.concern[concernIndex]}</div>, (Object.keys(activities.activity).map((data, index) => { 
            return <Row key={`${concernIndex}.${index}`} className="show-grid"> 
                <Col xs={1} sm={1}></Col>
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={6} sm={6} className="input">
                    <RRFInput
                        type="text"
                        model={`.activities[${concernIndex}].activity[${index}]`} 
                        defaultValue={activities.activity[index] || ''}
                    />
                </Col> 
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={2} sm={2} className="input">
                    <RRFInput
                        type="number"
                        model={`.activities[${concernIndex}].priority[${index}]`} 
                        defaultValue={(activities.priority &&  activities.priority[index]) || ''}
                    />
                </Col>
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={2} sm={2} className="input">
                    <div className='table-actions'>
                        <span className='delete-concern-type' 
                                onClick={() => this.deleteActivityRows(concernIndex, index)} ><i className="fa fa-trash-o" aria-hidden="true"></i></span> 
                    </div>
                </Col> 
            </Row>
        })) ]
    }               
    renderDynamicTable = () => {
        let concernTypes = this.props.forms.concernTypesForm;  
        return [concernTypes.concern.map((data, index) => {
            return <div><Row key={index} className="show-grid">
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={7} sm={7} className="input">
                    <RRFInput
                        type="text"
                        model={`.concern[${index}]`} 
                        defaultValue={data || ''}
                    />
                </Col> 
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={2} sm={2} className="input">
                    <RRFInput
                        type="number"
                        model={`.priority[${index}]`}
                        defaultValue={concernTypes.priority[index] || ''}
                    />
                </Col>
                <Col style={{ padding: '10px 15px', display: 'inline-block' }} xs={2} sm={2} className="input">
                    <div className='table-actions'>
                        <span className='delete-concern-type' 
                                onClick={() => this.deleteConcernTypeRows(index)} ><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                        <span className='add-activity'> 
                            <button
                                className="add-row"
                                type="button"
                                onClick={() => this.incrementActivityRows(index)} >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp;Add Activity
                            </button>
                        </span>
                    </div>
                </Col> 
            </Row>
            {this.renderDynamicActivities(index, concernTypes.activities[index])}</div>
       })];
    } 
    renderContent = () => {
        const {  } = this.props;
        return (
            <Grid className="concern-type-form" style={{ width: '100%' }}> 
                <Form loader={<SubmissionLoader />} model="forms.concernTypesForm" onSubmit={this.handleFormSubmit}>
                    <Title titleColor="yellow" text="Concern Types Form" noNeedBorder={true}/>
                    <AlertModal id="concern-type-modal" /> 
                    <fieldset> 
                        <Row key={-1} className="show-grid">
                            <Col style={{ padding: '10px 0px', display: 'inline-block', color: 'white', textAlign: 'center', fontSize: '16px' }} xs={7} sm={7} className="input">
                                Concern Type
                            </Col>
                            <Col style={{ padding: '10px 0px', display: 'inline-block', color: 'white', textAlign: 'center', fontSize: '16px' }} xs={2} sm={2} className="input">
                                Priority
                            </Col>
                            <Col style={{ padding: '10px 0px', display: 'inline-block', color: 'white', fontSize: '16px', textAlign: 'center' }} xs={2} sm={2} className="input">
                                Action
                            </Col>
                        </Row> 
                        {this.renderDynamicTable()}
                        <div className="table-buttons">
                            <button
                                className="add-row"
                                type="button"
                                onClick={this.incrementRows}>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                &nbsp;Add Concern Type
                            </button>
                        </div>
                    </fieldset>
                    <Row className="show-grid"> 
                        <div className="form-button" style={{ marginTop: '20px' }}>
                            <Alert className="danger-alert" id="designation-form-failed" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                onClick={() => this.props.history?.goBack() }
                                redirectTo="goBack" type="button"
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                            </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >  <i className={ 'fa fa-refresh' }  aria-hidden="true"></i>
                            &nbsp;
                            { 'Update' }
                        </Button>
                        </div>
                    </Row>
                </Form>
            </Grid>
        );
    }
 
    render() {
        return (
            <BasePage className="safeconnect-form"> 
                <Async
                    promise={this.promise}
                    identifier="ConcernTypesForm"
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
} 

export function mapStateToProps(state: any, ownProps: any) {   
    return { forms: state.forms, concernTypes: state.formSummary.get('concernTypes'), userUID: state.login.get('userUID'),};
}
export const ConcernTypesForm = withRouter(connect<IConcernTypesFormProps, any, any>(mapStateToProps)(ConcernTypesFormImpl));
