import * as React from 'react';
import { IHistory } from '../../interfaces';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'; 
import { IToolBoxMeetingModelProps, ToolBoxMeetingModel } from '../../model/ToolBoxMeetingModel';
import { Cell } from '../reusableComponents/ChartComponents/Cell';
import { connect } from 'react-redux'; 
import { showDelConfirmModal } from '../../actions/modalActions'; 
import { UserModel } from '../../model/UserModel';
import { getPageItemNumber } from '../../utils/generalUtils'; 
import { format } from 'date-fns';
const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: ToolBoxMeetingModel;
    style?: React.CSSProperties;
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> { 
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderDropdown = () => {
        const { instance } = this.props;
        if (!instance) {
            return;
        }
        return (
            <DropdownButton title="" id="bg-vertical-dropdown-3"> 
                { UserModel.checkUserAccess('tsm_view') && <MenuItem eventKey="1"
                    onClick={() => this.onViewAttribute(instance.props.id) }
                    className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                { UserModel.checkUserAccess('tsm_edit') && <MenuItem eventKey="2"
                    onClick={() => this.onEditAttribute(instance.props)}
                    className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> }
                { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="3"
                    onClick={() => this.onDeleteAttribute('archive')}
                    className="item"><i className="fa fa-archive" aria-hidden="true"></i> Archive</MenuItem> }
                { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="4"
                    onClick={() => this.onDeleteAttribute('delete')}
                    className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
            </DropdownButton>
        );
    }
    onDeleteAttribute(actionType: string) {
        const { instance } = this.props; 
        instance && showDelConfirmModal('delete-confirmation', 'TSM Form', instance.props, actionType);  
    }
    onEditAttribute(instance: IToolBoxMeetingModelProps) { 
        this.props.history?.push(`/tool-box-meeting/edit/${instance.key}`);
    }
    onViewAttribute(id: string) { 
        if(!UserModel.checkUserAccess('jsa_view') )
            return;
        this.props.history?.push(`/tool-box-meeting/view/${id}`)
    }
    renderFormList = () => {
        const { instance, index, style } = this.props;  
        if (!instance) {
            return <MuiThemeProvider>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <CircularProgress size={50} color="#88A4CE" />
                </div>
            </MuiThemeProvider>;
        }
        return (
            <Row className="report-row" style={style}> 
                <Col sm={1}>
                    {( UserModel.checkUserAccess('tsm_view') || UserModel.checkUserAccess('tsm_edit') || UserModel.checkUserAccess('tsm_delete') ) && this.renderDropdown()}
                    <span className="serial-no">{getPageItemNumber(index || 0, this.props.currentPage)}</span>
                </Col>
                <Cell
                    onClick={() => this.onViewAttribute(instance.props.id) }
                    sm={2}
                >
                    { (instance.props.dateOfIncident && format(new Date(instance.props.date),'MM/dd/yyyy')) || 'NA'}
                </Cell>
                <Cell
                    onClick={() => this.onViewAttribute(instance.props.id) }
                    sm={2}
                >
                    {instance.props.locationName || 'NA'}
                </Cell> 
                <Cell
                    onClick={() => this.onViewAttribute(instance.props.id) }
                    sm={2}
                >
                    {instance.props.projectName || 'NA'}
                </Cell>
                <Cell
                    onClick={() => this.onViewAttribute(instance.props.id) }
                    sm={2}
                >
                    {instance.props.groupName || 'NA'}
                </Cell> 
                <Col className="clickable-list-item" sm={3} onClick={() => this.onViewAttribute(instance.props.id) }>  
                    {instance.props.taskToPerform || 'NA'}  
                </Col> 
            </Row >
        );
    }

    render() {
        return this.renderFormList();
    }
}

export function mapStateToProps(state: any) {
    return {
        
    };
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
