import { DELETE_PROJECT_FAILED, FORM_SUBMISSION_ERROR, DUPLICATE_PROJECT } from '../constants/general';
import { IHistory } from '../interfaces';
import { IProjectModelProps, ProjectModel } from '../model/ProjectModel';
import { showAlert } from '../utils/generalUtils';
import { post, put, delCustom } from '../utils/HTTP';
import { GroupModel } from '../model/GroupModel';
import { getHomeData } from './miscellaniousServices';
import { getUserInfo } from './loginService';
import { UserModel } from '../model/UserModel';

export async function getAllProjects(requestFrom='') {
    try {
        let params = {userUID: UserModel.getUserUID(), requestFrom}
        let { data: { projectInfo } } = await post('/GetAllProjects', params);  
        /* let { data: { projectInfo } } = await get(`/GetAllProjects`);   */
        ProjectModel.deleteAll();
        (projectInfo || []).forEach((projectInstance: IProjectModelProps) => {
            let instance = marshallProjectInstance(projectInstance); 
            new ProjectModel(instance).$save();
        });
        return true;
    } catch (error) {
        throw error;
    }
}

export function marshallProjectInstance(data: IProjectModelProps) {
    data.id = data.projectId;
    let groupsData: any = [];
    data.groups.forEach(groupName => {
        const groupId = GroupModel.getIdByName(groupName);
        groupsData.push(groupId);

    });
    data.groupId = groupsData;
    return data;
}

export async function submitProjectForm(submittedValues: IProjectModelProps, history: IHistory) {
    if (submittedValues.groups[0].length <= 0) {
        submittedValues.groups = [];
    }
    try {
        let response = await post('/CreateProject', submittedValues);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'create-project', 'danger');
            return;
        }
        showAlert(response.data.message, 'create-project-modal', 'success', () => { history.push('/home'); });
        getAllProjects();
        await getHomeData();
        return response;
    } catch (error: any) {
        if (error.toString().indexOf('already exists') > -1) {
            showAlert(DUPLICATE_PROJECT, 'create-project', 'danger');
            return error;
        }
        showAlert(FORM_SUBMISSION_ERROR, 'create-project', 'danger');
        return error;
    }
}

export async function updateProjectForm(data: IProjectModelProps, history: IHistory, projectId: string) {
    if (!data.groups || data.groups[0].length <= 0) {
        data.groups = [];
    }
    try {
        let response = await put('/CreateProject', data);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'update-project', 'danger');
            return;
        }
        getAllProjects();
        showAlert(response.data.message, 'update-project-modal', 'success', () => { history.push('/home'); });
        return response;
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'update-project', 'danger');
        return error;
    }
}

export async function deleteProject(data: ProjectModel, history?: IHistory) {
    try {
        let response = await delCustom(`/CreateProject/${UserModel.getCompanyName()}/${data.props.projectId}`, data);
        new ProjectModel(data.props).$delete(); 
        await getHomeData();
        history && history.push('/teams');
        showAlert(response.data.message, 'delete-project-success', 'success', () => { });
        return response;
    } catch (error) {
        showAlert(DELETE_PROJECT_FAILED, 'project-details', 'danger');
        return error;
    }
}
export function getLoggedTeamList() {
    let groupsByTeam = getUserInfo().groupsByProject;
    let teamList:Array<any> = [];
    teamList.push({ value: 'all', label: 'All' });
    if(groupsByTeam) {  
        for(var team in groupsByTeam) {    
            teamList.push({ value: team, label: team.toString(), color: "red" }); 
        }
    } 
    return teamList;
}
