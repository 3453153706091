import * as React from 'react';
import { Async } from '../reusableComponents/Async';
import { Card } from '../reusableComponents/Card';
import { Title } from '../reusableComponents/Title';
import { BasePage } from '../reusableComponents/BasePage';
import { Loader } from '../reusableComponents/Loader';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { UserModel } from '../../model/UserModel';
import { GroupModel } from '../../model/GroupModel';
import { ProjectModel } from '../../model/ProjectModel';
import { IncidentNotificationModel } from '../../model/IncidentNotificationModel';
import { OIModel } from '../../model/OIModel';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { Container as Grid, Row, Col } from 'react-bootstrap'; 
import { getHomeData } from '../../services/miscellaniousServices';
import { getUserInfo } from '../../services/loginService';
import './homePage.scss';

import groupsIcon from '../../images/groupIcon.png';
import projectsIcon from '../../images/projectIcon.png';
import usersIcon from '../../images/userIcon.png';
import oiReportsIcon from '../../images/observationIcon.png';
import officeSafetyIcon from '../../images/officeSafetyLogo.png';
import jsaReportsIcon from '../../images/jsaIcon.png';
import pspReportsIcon from '../../images/pspIcon.png';
import managerSafetyIcon from '../../images/ManagerSafetyWlk.png';
import supervisorSafetyIcon from '../../images/SupervisorSafetWalk.png';
import incidentReportsIcon from '../../images/incidentIcon.png';
import investigativeIcon from '../../images/investigativeIcon.png';   
import { ELMSURL } from '../../constants/urls';

export interface IHomePageProps {
    userInstances: UserModel[];
    projectInstances: ProjectModel[];
    oiInstances: OIModel[];
    groupInstances: GroupModel[];
    history?: IHistory;
    incidentNotificationInstances: IncidentNotificationModel[];
    totalUsers: number;
    totalGroups: number;
    totalProjects: number;
    userInfo?: any;
}

export class HomePageImpl extends React.PureComponent<IHomePageProps, {}> {
    constructor(props: IHomePageProps | Readonly<IHomePageProps>) {
        super(props);
    }

    promise = async () => { 
        await getHomeData(); 
        return null;
    }
    processTrainingButton = () => { 
        const { userInfo } = this.props;  
        if(!userInfo.elmsFlag) { 
            /* showAlert('You are not authorized to access eLMS portal. Please contact your adminstrator', 'home-modal-success', 'danger-alert' );  */
            window.open(`${ELMSURL}`, '_blank'); 
        } else {
            window.open(`${ELMSURL}/login/${userInfo.elmsToken}`, '_blank'); 
        }
        return false;
    }

    renderTrainingButton = () => {
        const { userInfo } = this.props;  
        //return userInfo && userInfo.elmsFlag && <span className="elms-tab"><a href={`https://stage.spitraining.net/#/login/${userInfo.elmsToken}`} className="dropdown-item" target="_blank"> <img className="elms-logo" src="https://training-portal.s3.us-west-2.amazonaws.com/E-connect_LMS.png" alt="eLMS"/></a> </span>
        return userInfo && <span className="elms-tab"> <img onClick={() => this.processTrainingButton() } className="elms-logo" src="https://training-portal.s3.us-west-2.amazonaws.com/E-connect_LMS.png" alt="eLMS"/> </span> 
    }

    renderContent = () => {
        const { userInstances, projectInstances, oiInstances,
            groupInstances, incidentNotificationInstances, } = this.props;
        if (!userInstances || !projectInstances || !oiInstances || !groupInstances
            || !incidentNotificationInstances) {
            return;
        } 
        const smGrid = UserModel.getLoggedUserInstance()['accessLevel']==='L5' || !UserModel.checkUserAccess('closecall_add')?2:2;  
        const { totalGroups, totalProjects, totalUsers } = this.props; 
        return ( 
            <Grid style={{ width: '100%' }} className="homepage"> 
                <Title /* titleColor="white" */ text="Home" ></Title>  
                <AlertModal id="home-modal-success" /> 
                {this.renderTrainingButton()}
                <div className="links-container"> 
                    <Row className="show-grid">
                        { UserModel.checkUserAccess('o&i_add') && 
                            <Col sm={smGrid} xs={12}
                                className="top-row-left1 menu"
                                title="Click to fill out Observation &amp; Intervention Form"
                                onClick={() => this.props.history?.push('/oi-reports/create')}
                            >
                                <img className="icon icon-1" src={oiReportsIcon} alt="SafeConnect"/>
                                <span className="text">O&amp;I<br></br>Form</span>
                            </Col> }
                        { UserModel.checkUserAccess('office_safety_add') && 
                            <Col sm={smGrid} xs={12}
                                className="top-row-middle1 menu"
                                title="Click to fill out Office O&amp;I Form"
                                onClick={() => this.props.history?.push('/office-safety-reports/create')}
                            >
                                <img className="icon icon-2" src={officeSafetyIcon} alt="SafeConnect"/>
                                <span className="text">Office<br></br>O&amp;I</span>
                            </Col> }
                        { UserModel.checkUserAccess('personal_safty_add') &&
                            <Col sm={smGrid} xs={12}
                                className="top-row-right1 menu"
                                title="Click to fill out Personal Safety Form"
                                onClick={() => this.props.history?.push('/personal-safety/create')} >
                                <img className="icon icon-3" src={pspReportsIcon} alt="SafeConnect" />
                                <span className="text">Personal <br></br>Safety Plan</span>
                            </Col> }
                    {/* </Row>
                    <Row className="show-grid"> */}
                        { UserModel.checkUserAccess('jsa_add') &&
                            <Col sm={smGrid} xs={12}
                                className="middle-row-left1 menu"
                                title="Click to fill out Risk Assessment Form"
                                onClick={() => this.props.history?.push('/jsa-reports/create')} >
                                <img className="icon icon-5" src={jsaReportsIcon} alt="SafeConnect" />
                                <span className="text">Job Safety<br></br>Analysis</span>
                            </Col> }
                        { UserModel.checkUserAccess('risk_add') &&
                            <Col sm={smGrid} xs={12}
                                className="middle-row-left1 menu"
                                title="Click to fill out Risk Assessment Form"
                                onClick={() => this.props.history?.push('/risk-assessment/create')} >
                                <img className="icon icon-5" src={officeSafetyIcon} alt="SafeConnect" />
                                <span className="text">Risk<br></br>Assessment</span>
                            </Col> }
                        { UserModel.checkUserAccess('incident_notification_add') && 
                            <Col sm={smGrid} xs={12}
                                className="middle-row-right1 menu"
                                title="Click to fill out Incident Notitfication Form"
                                onClick={() => this.props.history?.push('/incident-reports/create')} >
                                <img className="icon  " src={incidentReportsIcon} alt="SafeConnect" />
                                <span className="text">Incident<br></br>Notification</span>
                            </Col> }
                        { UserModel.checkUserAccess('incident_investigative_add') &&
                            <Col sm={smGrid} xs={12}
                                className="middle-row-right1 menu"
                                title="Click to fill out Incident Investigative Form"
                                onClick={() => this.props.history?.push('/incident-investigative-reports/create')} >
                                <img className="icon icon-7" src={investigativeIcon} alt="SafeConnect" />
                                <span className="text">Investigative<br></br>Form</span>
                            </Col> }
                   {/*  </Row>
                    <Row className="show-grid"> */}
                        { UserModel.checkUserAccess('safety_walkthrough_add') &&
                            <Col sm={smGrid} xs={12}
                                className="middle-row-left1 menu"
                                title="Click to fill out Manager Safety Walkthrough"
                                onClick={() => this.props.history?.push('/safety-walkthroug/all')} >
                                <img className="icon" src={supervisorSafetyIcon} alt="SafeConnect" />
                                <span className="text"> Manager Safety<br></br>Walkthrough</span>
                            </Col> }
                        { UserModel.checkUserAccess('safety_walkthrough_add') &&
                            <Col sm={smGrid} xs={12}
                                className="middle-row-left1 menu"
                                title="Click to fill out Supervisor Safety Walkthrough"
                                onClick={() => this.props.history?.push('/supervisor-safetywalkthrough/all')} >
                                <img className="icon" src={managerSafetyIcon} alt="SafeConnect" />
                                <span className="text">Supervisor Safety<br></br>Walkthrough</span>
                            </Col> }
                        { UserModel.checkUserAccess('audit_inspection_add') &&
                            <Col sm={smGrid} xs={12}
                                className="bottom-row-left1 menu"
                                title="Click to fill out Audit & Inspection"
                                onClick={() => this.props.history?.push('/audit')} >
                                <img className="icon" src={supervisorSafetyIcon} alt="SafeConnect" />
                                <span className="text"> Audit & <br></br>Inspection</span>
                            </Col>
                        }
                        { UserModel.checkUserAccess('checklist_add') &&
                            <Col sm={smGrid} xs={12}
                                className="bottom-row-left1 menu"
                                title="Click to fill out Checklist"
                                onClick={() => this.props.history?.push('/checklist')} >
                                <img className="icon" src={managerSafetyIcon} alt="SafeConnect" />
                                <span className="text"> Checklist</span>
                            </Col>
                        }
                        { UserModel.checkUserAccess('closecall_add') &&
                            <Col sm={smGrid} xs={12}
                                className="bottom-row-right1 menu"
                                title="Click to fill out Close Call Form"
                                onClick={() => this.props.history?.push('/closecall')} >
                                <img className="icon" src={supervisorSafetyIcon} alt="SafeConnect" />
                                <span className="text">FRA<br></br>Close Call</span>
                            </Col>
                        } 
                        { UserModel.checkUserAccess('tsm_add') && 
                            <Col sm={smGrid} xs={12}
                                className="bottom-row-left1 menu"
                                title="Click to fill out Tool Box Meeting Form"
                                onClick={() => this.props.history?.push('/tool-box-meeting/create')}
                            >
                                <img className="icon icon-1" src={oiReportsIcon} alt="SafeConnect" />
                                <span className="text">Toolbox Safety<br></br>Meeting</span>
                            </Col> }
                        { UserModel.checkUserAccess('tsm_add') && 
                            <Col sm={smGrid} xs={12}
                                className="bottom-row-middle1 menu"
                                title="Click to fill out Management Of Change Form"
                                onClick={() => this.props.history?.push('/management-of-change/create')}
                            >
                                <img className="icon icon-1" src={incidentReportsIcon} alt="SafeConnect" />
                                <span className="text">Management Of<br></br>Change</span>
                            </Col> }
                        { UserModel.checkUserAccess('texas811_forms') && 
                            <Col sm={smGrid} xs={12}
                                className="bottom-row-right1 menu texas811"
                                title="Click to fill out Texas811 Forms"
                                onClick={() => this.props.history?.push('/texas811')} >
                                <img className="icon" src={investigativeIcon} alt="SafeConnect" />
                                <span className="text">Texas811<br/>Forms</span>
                            </Col> }
                        { UserModel.checkUserAccess('utility_forms') && 
                            <Col sm={smGrid} xs={12}
                                className="bottom-row-middle1 menu"
                                title="Click to fill out Utility Forms"
                                onClick={() => this.props.history?.push('/utility-forms')} >
                                <img className="icon" src={investigativeIcon} alt="SafeConnect" />
                                <span className="text">Utility<br/>Forms</span>
                            </Col> }
                        { UserModel.checkUserAccess('utility_forms') && 
                            <Col sm={smGrid} xs={12}
                                className="bottom-row-middle1 menu"
                                title="Click to fill out Permit to Work"
                                onClick={() => this.props.history?.push('/permit-to-work/create')} >
                                <img className="icon" src={officeSafetyIcon} alt="SafeConnect" />
                                <span className="text">Permit to<br/>Work(PTW)</span>
                            </Col> } 
                    </Row>
                </div>
                { ( UserModel.checkUserAccess('users_list') || UserModel.checkUserAccess('groups_list') || UserModel.checkUserAccess('projects_list') ) && <Row className="card-container show-grid" >  
                    { UserModel.checkUserAccess('users_list') &&

                        <Col sm={4}
                        title="Click to view the List of available Teams"
                        onClick={() => this.props.history?.push('/teams')}
                        >
                        <Card width="100%" className="homepage-card">
                            <img className="card-icon" src={projectsIcon} alt="SafeConnect" />
                            <div className="card-details">
                                <h4 className="card-label" >Teams</h4>
                                <p className="card-data">{totalProjects}</p>
                            </div>
                        </Card>
                        </Col>
                        
                    }
                    { UserModel.checkUserAccess('groups_list') &&
                        <Col
                            sm={4}
                            title="Click to view the List of available Groups"
                            onClick={() => this.props.history?.push('/groups')}
                        >
                            <Card width="100%" className="homepage-card">
                                <img className="card-icon" src={groupsIcon} alt="SafeConnect" />
                                <div className="card-details">
                                    <h4 className="card-label" >Groups</h4>
                                    <p className="card-data">{totalGroups}</p>
                                </div>
                            </Card>
                        </Col>
                    }
                    { UserModel.checkUserAccess('projects_list') &&
                        <Col
                            sm={4}
                            title="Click to view the List of available Users"
                            onClick={() => this.props.history?.push('/users')} >
                            <Card width="100%" className="homepage-card">
                                <img className="card-icon" src={usersIcon} alt="SafeConnect" />
                                <div className="card-details">
                                    <h4 className="card-label" >Users</h4>
                                    <p className="card-data">{totalUsers}</p>
                                </div>
                            </Card>
                        </Col>
                    }
                </Row> } 
            </Grid > 
        );
    }

    render() {
        return (
            <BasePage>
                <Async
                    identifier="HomePage"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const userInstances = UserModel.list();
    const projectInstances = ProjectModel.list();
    const oiInstances = OIModel.list();
    const groupInstances = GroupModel.list();
    const incidentNotificationInstances = IncidentNotificationModel.list();
    const totalGroups = state.miscellaneous.get('totalGroups');
    const totalProjects = state.miscellaneous.get('totalProjects');
    const totalUsers = state.miscellaneous.get('totalUsers');  
    let userInfo = getUserInfo();
    return {
        userInstances, projectInstances, oiInstances, groupInstances, incidentNotificationInstances, totalGroups, totalProjects, totalUsers, userInfo, 
    };
}

export const HomePage = withRouter(connect<IHomePageProps, any, any>(mapStateToProps)(HomePageImpl));
