import {setSuccess} from '../actions/loadingActions';
import {saveIncidentNotificationSummary} from '../actions/summaryActions';
import {FORM_SUBMISSION_ERROR} from '../constants/general';
import {IAnswer, IForms, IHistory} from '../interfaces';
import {IIncidentNotificationModelProps, IncidentNotificationModel} from '../model/IncidentNotificationModel';
import {UserModel} from '../model/UserModel';
import {Answers} from '../utils/Answers';
import {showAlert} from '../utils/generalUtils';
import {getCustom, post} from '../utils/HTTP'; 
import {format} from 'date-fns';
import {getNotifications} from './profileService';
import {saveListCount} from '../actions/miscellaneousActions'; 

export async function getAllIncidentNotificationForms(urlData?: any, forms?: IForms) { 
    try { 
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        let data = { ...forms?.commonFilterForm, formType: 'incidentReport', pageNumber, sortIndexField, sortingOrder, searchItem }; 
        const response = await post('/GetForms', data, axiosPreviousToken); 
        if (response.data.status === 'valid') {
            IncidentNotificationModel.deleteAll();
            response.data.data.forEach((incidentFormData: IIncidentNotificationModelProps) => {
                const incidentFormInstance = marshallIncidentNotificationInstance(incidentFormData);
                new IncidentNotificationModel(incidentFormInstance).$save();
            });
            saveListCount(response.data.TotalPages);
        }
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function GetIncidentFormsForInvestigate(type: any) {
    const company = UserModel.getCompanyName();
    try {
        const data = {formType: 'incidentReport', company, fetchType: type};
        const response = await post('/GetIncidentFormsForInvestigate', data);

        if (response.data.status === 'valid') {
            IncidentNotificationModel.deleteAll();
            if (response.data.data !== 'No Data Found') {
                (response.data.data || []).forEach((incidentFormData: IIncidentNotificationModelProps) => {
                    const incidentFormInstance = marshallIncidentNotificationInstance(incidentFormData);
                    new IncidentNotificationModel(incidentFormInstance).$save();
                });
            }
        }
        return response.data;
    } catch (error) {
        throw error;
    }
}

export function marshallIncidentNotificationInstance(data: IIncidentNotificationModelProps) {
    data.id = data.key;
    data.submittedFormId = data.key;
    const answerData = new Answers(data.answers);
    const answers = {
        dateOfIncident: format(new Date(answerData.get(1)), 'MM/dd/yyyy'),
        timeOfIncident: answerData.get(2),
        location: answerData.get(3),
        specifiedLocation: answerData.get(4),
        injuredPartyIdNo: answerData.get(5),
        team: answerData.get(6),
        injuredPartyWorkFunction: answerData.get(7),
        activityBeingPerformed: answerData.get(8),
        concernType: answerData.get(9),
        activity: answerData.get(10),
        injuryDiagnosis: answerData.get(11),
        injurySummary: answerData.get(12),
        injuryBodyPart: answerData.get(13),
        injuryClassification: answerData.get(14),
        associatedFormsCompleted: data.associatedFormsCompleted
    };
    Object.assign(data, answers);
    delete data.answers;
    return data;
}

export async function submitIncidentReport(answers: IAnswer[], userId: string, followUpNeeded: boolean, date: string, history: IHistory | undefined, group: string, project: string, forms: IForms, fillFollowUpForm: boolean, editId?: string) { 
    const submittedValues: any = {
        groupName: group,
        userId,
        formId: 'incidentReport',
        date: format(new Date(), 'yyyy-MM-dd'),
        time: format(new Date(), 'HH:mm'),
        projectName: project,
        followUp: followUpNeeded || '0',
        answers,
        associatedFormId: '', 
        deletedImages: [],
        deletedVideos: [],
        editId: '', 
        imageData: forms.incidentNotificationForm.incidentNotificationImages || [],
        videoData: forms.incidentNotificationForm.incidentNotificationVideos || []
    }; 
    try { 
        submittedValues.deletedImages = forms.incidentNotificationForm.deletedImages || [];
        submittedValues.deletedVideos = forms.incidentNotificationForm.deletedVideos || [];
        submittedValues.editId = editId;
        const response = submittedValues.editId ? await post('/EditForm', submittedValues) : await post('/SubmitForm', submittedValues); 
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            setSuccess('IncidentNotificationForm');
            showAlert(FORM_SUBMISSION_ERROR, 'incident-notification-form', 'danger');
            return;
        }
        if (!fillFollowUpForm) {
            showAlert(response.data.message, 'incident-modal', 'success', async () => {
                history?.push('/home');
                //await getAllIncidentNotificationForms();
                await getNotifications(userId, UserModel.getCompanyName());
            });
            //getIncidentNotificationFormSummaryData();
            setSuccess('IncidentNotificationForm');
            return;
        }
        const submittedFormId = response.data.submittedFormId;
        //await getAllIncidentNotificationForms();
        history?.push(`/incident-investigative-reports/create/${submittedFormId}`);
        await getNotifications(userId, UserModel.getCompanyName());
        setSuccess('IncidentNotificationForm');
        return; 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'incident-notification-form', 'danger');
    }
}
 
export async function getIncidentNotificationFormSummaryData(forms?: IForms ) { 
    try {
        const data = { teams: forms?.commonFilterForm.teams || '', groups: forms?.commonFilterForm.groups || '', fetchType: forms?.commonFilterForm.filterType || 'summary', year: forms?.commonFilterForm.year || '', month: forms?.commonFilterForm.month || '', reportStatus: forms?.commonFilterForm.reportStatus || 'active', }; 
        const response = await post('/GetIncidentReportSummary', data); 

        const concernTypes = {
            Safety: 0,
            Health: 0,
            Environment: 0,
            Security: 0,
            Other: 0
        };
        concernTypes.Safety = response.data.data.concernTypes.Safety;
        concernTypes.Health = response.data.data.concernTypes.Health;
        concernTypes.Security = response.data.data.concernTypes.Security;
        concernTypes.Environment = response.data.data.concernTypes.Environment;
        concernTypes.Other = response.data.data.concernTypes.Other;
        delete response.data.data[('concernTypes')];
        response.data.data.concernTypes = concernTypes;
        saveIncidentNotificationSummary(response.data.data);
    } catch (error) {
        throw error;
    }
}

export async function applyYearlyFilter(year: string | undefined) {
    if (!year) {
        setSuccess('ReportsPage');
        showAlert('Please select year first.', 'incident-report-summary', 'danger');
        return;
    }
    //await getIncidentNotificationFormSummaryData('yearly', year);
    setSuccess('ReportsPage');
}

export async function applyMonthlyFilter(month: string | undefined, year: string | undefined) {
    if (!month) {
        setSuccess('ReportsPage');
        showAlert('Please select month first.', 'incident-report-summary', 'danger');
        return;
    } else if (!year) {
        showAlert('Please select year.', 'incident-report-summary', 'danger');
        setSuccess('ReportsPage');
        return;
    }
    //await getIncidentNotificationFormSummaryData('monthly', year, month);
    setSuccess('ReportsPage');
}

export function getAllIncidentNotificationFormsOptions(incidentNotificationInctances: IncidentNotificationModel[], removeFilledInvestications: boolean = false) {
    let incidentNotificationForms: Array<any> = [];
    incidentNotificationForms.push({
        value: 'No Report',
        label: 'No Report', date: ''
    });
    incidentNotificationInctances.forEach((instance, index) => {
        //if(instance.props.associatedFormsCompleted.length==0 || !removeFilledInvestications) {
        if (!removeFilledInvestications) {
            incidentNotificationForms.push({
                value: instance.props.submittedFormId,
                label: instance.props.injuryBodyPart +' - ' + instance.props.dateOfIncident, date: instance.props.dateOfIncident
            });
        }
    });
    return incidentNotificationForms;
}


export async function getIncidentForm(formId: string) {
    const company = UserModel.getCompanyName();
    try {
        const response = await getCustom(`GetForms/incidentReport/${company}/${formId}`);
        if (response.data.status === 'valid') {
            IncidentNotificationModel.deleteAll();
            response.data.data.forEach((incidentFormData: IIncidentNotificationModelProps) => {
                const incidentFormInstance = marshallIncidentNotificationInstance(incidentFormData);
                new IncidentNotificationModel(incidentFormInstance).$save();
            });
            saveListCount(response.data.TotalPages);
        }
        return response.data;
    } catch (e) {
        throw e
    }  
}
