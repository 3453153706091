import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Col, Row, DropdownButton, Dropdown, Button} from 'react-bootstrap';
import {IHistory} from '../../../interfaces';
import {MocTemporaryModel} from "../../../model/MOCModel";
import {showDelConfirmModal} from '../../../actions/modalActions';
import {getPageItemNumber, getTemporaryDeviationStatus} from '../../../utils/generalUtils';
import {UserModel} from '../../../model/UserModel';
import {showCloseCallFollowUpListModal} from '../../../actions/modalActions';
import {setLoading} from '../../../actions/loadingActions';
import {getAllFollowUpForms} from '../../../services/closeCallService';
import {Cell} from '../../reusableComponents/ChartComponents/Cell';

const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: MocTemporaryModel;
    style?: React.CSSProperties;
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;
    approvalStatus: any = '';
    renderRow = () => {
        const {index, instance, style} = this.props;
        if (!instance) {
            return <div/>;
        }
        const {props: {date, id, answers: {groupName, originator}}}: any = instance;
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
        }
        return <Row
            className="report-row"
            style={style}
        >
            <Col sm={1}>
                {<DropdownButton title="" id="bg-vertical-dropdown-3">
                    <MenuItem eventKey="1"
                              onClick={() => this.props.history?.push(`/moc-temporary-deviation/detail/${instance.props.id}`)}
                              className="item"><i className="fa fa-eye" aria-hidden="true"/> View</MenuItem>
                    <MenuItem eventKey="2"
                              onClick={() => this.onEditAttribute(instance.props)}
                              className="item"><i className="fa fa-pencil" aria-hidden="true"/> Edit</MenuItem>
                    { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="3"
                            onClick={() => this.onDeleteAttribute('archive')}
                            className="item"><i className="fa fa-archive" aria-hidden="true"></i> Archive</MenuItem> }
                    { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="4"
                            onClick={() => this.onDeleteAttribute('delete')}
                            className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                </DropdownButton>}
                <span className="serial-no m-l10">{getPageItemNumber(index || 0, this.props.currentPage)}</span>
            </Col>
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{ date || 'NA'}</Cell> 
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{originator || 'NA'}</Cell> 
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{groupName || 'NA'}</Cell>
            <Col sm={2} onClick={() => this.onViewAttribute(id)}>{this.getStatusCount(answer)}</Col> 
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{this.approvalStatus}</Cell> 
        </Row>;
    }
    getStatusCount(answer: any) {
        /* let completed: any = '';
        let statusClass: any = 'default';
        let total: any = '6';
        if(answer.productionManager) {
            completed = answer.globalOperationsManagerApprovedDate?'6':answer.regionalVicePresidentApprovedDate?'5':answer.generalManagerApprovedDate?'4':answer.productionManagerApprovedDate?'3':answer.operationsManagerApprovedDate?'2':answer.safetyManagerApprovedDate?'1':'0';
            if(completed==='6')
                statusClass = 'success';
            else
                statusClass = 'default'; 
        } else {
            completed = answer.globalOperationsManagerApprovedDate?'4':answer.regionalVicePresidentApprovedDate?'3':answer.operationsManagerApprovedDate?'2':answer.safetyManagerApprovedDate?'1':'0';
            if(completed==='4')
                statusClass = 'success';
            else
                statusClass = 'default';
            total = '4'; 
        } 
        if(statusClass==='success') {
            this.approvalStatus = 'Approved';
        } else if(statusClass==='default') {
            this.approvalStatus = ' - ';
        } */
        const {total, completed, approvalStatus, statusClass} = getTemporaryDeviationStatus(answer);
        this.approvalStatus = approvalStatus;
        return  <Button className={`moc-status-count ${statusClass}`} > {completed}/{total}</Button> 

    }

    getFollowUpList(instance: any) {
        if (!UserModel.checkUserAccess('follow_up_list'))
            return;
        let modalInstance: any = [];
        modalInstance['closeCallId'] = instance['id'];
        modalInstance['followUpFor'] = instance;
        showCloseCallFollowUpListModal(modalInstance);
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['id']);
    }

    onDeleteAttribute(actionType: string) {
        const {instance} = this.props;
        instance && showDelConfirmModal('delete-confirmation', 'MOC-Temporary Deviation Report', instance.props, actionType);
    }

    onEditAttribute(instance: any) {
        this.props.history?.push(`/moc-temporary-deviation/edit/${instance.key}`);
    }

    onViewAttribute(id: string) {
        if (!UserModel.checkUserAccess('o&i_view'))
            return;
        this.props.history?.push(`/moc-temporary-deviation/detail/${id}`);
    }

    render() {
        return this.renderRow();
    }

}

export function mapStateToProps(state: any) {
    return {}
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
