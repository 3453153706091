import { saveFilteredGroups } from '../actions/groupAction';
import { DELETE_GROUP_FAILED, FORM_SUBMISSION_ERROR, DUPLICATE_GROUP } from '../constants/general';
import { IHistory } from '../interfaces';
import { GroupModel, IGroupModelProps } from '../model/GroupModel';
import { showAlert } from '../utils/generalUtils';
import { post, put, delCustom } from '../utils/HTTP';
import { UserModel } from '../model/UserModel';
import { getHomeData } from './miscellaniousServices';
import { getUserInfo } from './loginService';
export async function getFilteredGroups(projectIds: string, dataType: string = 'projectId') { 
    let groupList: any[] = [];
    if(projectIds) {
        //let { data: { projectInfo } } = await get('/GetAllProjects'); 
        let params = {userUID: UserModel.getUserUID(),'requestFrom': 'all'}
        let { data: { projectInfo } } = await post('/GetAllProjects', params);
        (projectInfo || []).forEach((projectInstance: { projectId: string; projectName: string; groups: any[]; }) => { 
            const projectID = projectIds.split(',');
            projectID.forEach(projectid => {
                if ( (dataType==='projectId' && projectInstance.projectId === projectid) || (dataType==='projectName' && projectInstance.projectName === projectid) ) {
                    projectInstance.groups.forEach(groupName => {
                        groupList.push(groupName);
                    });
                }
            });
        });
    }  
    saveFilteredGroups(groupList);
}

export async function getAllGroups(requestFrom='') {
    try {
        //let { data: { groupInfo } } = await get('/GetAllGroups');  
        let params = {userUID: UserModel.getUserUID(), requestFrom}
        let { data: { groupInfo } } = await post('/GetAllGroups', params); 
        GroupModel.deleteAll();
        (groupInfo || []).forEach((groupName: IGroupModelProps) => {
            const instance = marshallGroupsInstance(groupName);
            new GroupModel(instance).$save();
        });
    } catch (error) {
        throw error;
    }
}

export function marshallGroupsInstance(data: IGroupModelProps) {
    data.id = data.groupId;
    data.groupMembers = data.members && data.members.toString();
    data.groupForms = data.groupForms && data.groupForms.toString();
    return data;
}

export async function submitGroupForm(submittedValues: IGroupModelProps, history: IHistory) {
    try {
        const response = await post('/CreateGroup', submittedValues);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'add-group', 'danger');
            return;
        }
        await getAllGroups();
        await getHomeData();
        showAlert(response.data.message, 'group-modal', 'success',
            () => { history.push('/home'); });
        return response;
    } catch (error: any) {
        if (error.toString().indexOf('already exists') > -1) {
            showAlert(DUPLICATE_GROUP, 'add-group', 'danger');
            throw error;
        }
        showAlert(FORM_SUBMISSION_ERROR, 'add-group', 'danger');
        throw error;
    }
}

export async function updateGroupForm(submittedValues: IGroupModelProps, history: IHistory) {
    try {
        let response = await put('/CreateGroup', submittedValues);
        if (JSON.stringify(response.data).indexOf('error') > 0) {
            showAlert(FORM_SUBMISSION_ERROR, 'update-group', 'danger');
            return;
        }
        await getAllGroups();
        showAlert(response.data.message, 'update-group-modal', 'success', () => { history.push('/home'); });
        return response;
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'update-group', 'danger');
        return error;
    }
}

export async function deleteGroup(submittedValues: GroupModel, history: IHistory) {
    try {
        let response = await delCustom(`/CreateGroup/${UserModel.getCompanyName()}/${submittedValues.props.groupId}`, submittedValues); 
        await getHomeData();
        history.push('/groups');
        showAlert(response.data.message, 'delete-group-success', 'success', () => { history.push('/groups'); });
        new GroupModel(submittedValues.props).$delete();
        return response;
    } catch (error) { 
        showAlert( DELETE_GROUP_FAILED, 'delete-group-success', 'danger');
        return error;
    }
}
export function getLoggedGroupList(teamName?: string | undefined) {
    let groupsByTeam = getUserInfo().groupsByProject;
    let groupList:Array<any> = [];
    groupList.push({ value: 'all', label: 'All' });
    if(groupsByTeam) {  
        for(var team in groupsByTeam) { 
            if(!teamName || (teamName && teamName===team) || teamName==='all') {
                groupsByTeam[team].forEach((group: { toString: () => any; }) => { 
                    groupList.push({ value: group, label: group.toString() });
                });  
            }
        }
    } 
    return groupList;
}
