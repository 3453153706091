import './incidentNotificationForm.scss';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Container as Grid, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';

import { setLoading, setSuccess } from '../../actions/loadingActions';
import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { IForms, IHistory, ISelectOptions } from '../../interfaces';
import { GroupModel } from '../../model/GroupModel';
import { UserModel } from '../../model/UserModel';
import { DesignationModel } from '../../model/MiscellaneousModel';
import { getAllGroups } from '../../services/groupService';
import { submitIncidentReport } from '../../services/incidentNotificationFormService';
import { getAllUsers } from '../../services/userService';
import { constructAnswers, dispatch, isFormEmpty, showAlert } from '../../utils/generalUtils';
import { Alert } from '../Alert';
//import { ConfirmationModal } from '../OIForm/ConfirmationModal';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ConcernDropdown } from '../reusableComponents/ConcernTypesComponents/ConcernDropdown';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { FileInput } from '../reusableComponents/FormComponents/FileInput';
import { VideoInput } from '../reusableComponents/FormComponents/VideoInput';
import { Form } from '../reusableComponents/FormComponents/Form';
import { Label } from '../reusableComponents/FormComponents/Label';
import { LocationInput } from '../reusableComponents/FormComponents/LocationInput';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput'; 
import { ProjectInput } from '../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../reusableComponents/FormComponents/GroupInput';
import { Loader } from '../reusableComponents/Loader';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title';
import { MapInputModal } from '../reusableComponents/FormComponents/MapInputModal'; 
import { IncidentNotificationModel } from '../../model/IncidentNotificationModel';
import { getAllIncidentNotificationForms, } from '../../services/incidentNotificationFormService';
import { getAllDesignations } from '../../services/miscellaniousServices';
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';

export interface IIncidentNotificationFormProps {
    userUID: string;
    userId: string;
    history?: IHistory;
    forms: IForms;
    groupInstances?: GroupModel[];
    activityOptions?: ISelectOptions[];
    company: string;
    userInstance?: UserModel;
    editInstance?: IncidentNotificationModel;
    editId?: string;
    /* teamInstance?: TeamModel; */
    designationInstance: DesignationModel; 
}

export interface IIncidentNotificationFormState {
    currentDate: string;
    currentTime: string;
    specifiedLocation: string;
    selectedTeam: string;
    selectedInjuryClassification: string;
    /**
     * Once data from API fetches remove these and take them as props and get value from store
     */
    selectedInjuryBodyPart: string;
    injuryBodyPartOptions?: ISelectOptions[];
    injuryClassificationOptions?: ISelectOptions[];
    locationOptions?: ISelectOptions[];
    longitude: number;
    latitude: number;
    showConfirmationModal?: boolean;
}
export class IncidentNotificationFormImpl extends React.PureComponent<IIncidentNotificationFormProps,
    IIncidentNotificationFormState> {

    /**
     * Initializing the initial values of the drop down. Remove this if the data of the drop down comes from Server.
     */
    constructor(props: IIncidentNotificationFormProps | Readonly<IIncidentNotificationFormProps>) {
        super(props);
        this.state = {
            currentDate: format(new Date(), 'yyyy-MM-dd'),
            currentTime: format(new Date(), 'HH:mm'),
            locationOptions: [{ value: 'SPI 1029', label: 'SPI 1029' },
            { value: 'SPI 1033', label: 'SPI 1033' },
            { value: 'BNSF', label: 'BNSF' },
            { label: '7 Eleven', value: '7 Eleven' }],
            injuryBodyPartOptions: [{ value: 'Hand', label: 'Hand' }, { value: 'Head', label: 'Head' },
            { value: 'Limb', label: 'Limb' }, { value: 'Foot', label: 'Foot' }, { value: 'Chest', label: 'Chest' },
            { value: 'Other', label: 'Other' }],
            injuryClassificationOptions: [
                { value: 'No Treat (NT)', label: 'No Treat (NT)' },
                { value: 'Near Miss (NM)', label: 'Near Miss (NM)' },
                { value: 'Serious Near Miss (SNM)', label: 'Serious Near Miss (SNM)' },
                { value: 'First Aid (FA)', label: 'First Aid (FA)' },
                { value: 'Medical Treatment (MTI)', label: 'Medical Treatment (MTI)' },
                { value: 'Lost Time (LTI)', label: 'Lost Time (LTI)' },
                { value: 'Fatality (FAT)', label: 'Fatality (FAT)' }
            ],
            specifiedLocation: '',
            selectedTeam: '',
            selectedInjuryBodyPart: '',
            selectedInjuryClassification: '',
            longitude: 0,
            latitude: 0,
            showConfirmationModal: false
        };
    }

    identifier = 'IncidentNotificationForm';

    fillFollowUpForm = false;

    componentWillMount() {
        setSuccess(this.identifier);
        dispatch(actions.reset('forms.incidentNotificationForm'));
        dispatch(actions.change('forms.incidentNotificationForm.changedConcernType', ''));
    }

    getChildContext() {
        return { formModel: 'forms.incidentNotificationForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    promise = async () => {
        if (GroupModel.list().length === 0) {
            await getAllGroups();
        }
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        /* if (TeamModel.list().length <= 1) {
            await getCompanyTeams();
        } */
        if (DesignationModel.list().length === 0) {
            await getAllDesignations();
        }
        if(this.props.editId && !this.props.editInstance) {
            await getAllIncidentNotificationForms();
        }
        return null;
    }

    redirectToHome = () => {
        this.props.history?.push('/home');
    }
    renderForm = (showLoader: boolean) => {
        const { handleSubmit, state: { currentDate, currentTime, injuryBodyPartOptions, injuryClassificationOptions }, props: { activityOptions, editInstance, editId, forms } } = this;
        if(editId && !editInstance) {
            //history.push('/incident-reports');
            return;
        }   
        return <Form model="forms.incidentNotificationForm" onSubmit={handleSubmit}>
            {showLoader ? <SubmissionLoader /> : null}
            <MapInputModal id="incident-notification-form" />
            {this.showModal()}
            <Title titleColor="yellow" text="Incident Notification Form" noNeedBorder={true} />
            <AlertModal id="incident-modal" />
            <fieldset>
                <legend><span style={{ color: 'yellow' }}>Incident Notification</span></legend>
                <Row className="show-grid" >
                    <Col sm={4} xs={12}>
                        <Label required sm={12} htmlFor="currentDate">Date Of Incident:</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="currentDate"
                                type="date" 
                                model=".dateOfIncident"
                                defaultValue={editInstance ? format(new Date(editInstance.props.dateOfIncident), 'yyyy-MM-dd') : currentDate} 
                                maxdate={editInstance? format(new Date(editInstance.props.date), 'yyyy-MM-dd'): currentDate} 
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Label sm={12} htmlFor="currentTime">Time of Incident:</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="currentTime"
                                defaultValue={editInstance ? editInstance.props.timeOfIncident : currentTime}
                                type="time"
                                model=".timeOfIncident"
                            />
                        </Col>
                    </Col>
                    <LocationInput
                        id="incident-notification-form" 
                        companyName={this.props.company}
                        navigator={navigator}
                        model=".specifiedLocation"
                        defaultValue={editInstance && editInstance.props.specifiedLocation}
                        specifiedLocation={editInstance && editInstance.props.specifiedLocation}
                    />
                </Row>
                <Row className="show-grid">
                    <Col sm={3} xs={12}>
                        <ProjectInput model=".projectName" id="project" defaultValue={editInstance ? editInstance.props.projectName : this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0]} />
                    </Col>
                    <Col sm={3} xs={12}>
                        <GroupInput model=".groupName" id="group" defaultValue={editInstance ? editInstance.props.groupName : this.props.userInstance && this.props.userInstance.props.groups && this.props.userInstance.props.groups[0]} />
                    </Col> 
                    <Col sm={3} xs={12}>
                        <Label required sm={12} htmlFor="injuredPartyIdNo">Injured party's ID Number:</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="injuredPartyIdNo"
                                placeholder="Enter injured party's ID no..."
                                type="text"
                                model=".injuredPartyIdNo"
                                defaultValue={editInstance && editInstance.props.injuredPartyIdNo}
                            />
                        </Col>
                    </Col>
                    <Col sm={3} xs={12}>
                        <Label sm={12} htmlFor="team">Designation:</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="team"
                                type="dropdown"
                                menuItems={this.getDesignations()}
                                model=".team"
                                defaultValue={editInstance && editInstance.props.team}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col sm={6} xs={12}>
                        <ConcernDropdown model=".concernType" id="concernType" defaultValue={editInstance && editInstance.props.concernType}/>
                    </Col>
                    <Col sm={6} xs={12}>
                        <Label required sm={12} htmlFor="activity">Activity:</Label>
                        <Col sm={12} className="input">
                            {forms.incidentNotificationForm.changedConcernType==='Other' || ( forms.incidentNotificationForm.concernType==='Other' && ( (editInstance && editInstance.props.concernType === 'Other') || !editInstance) )  ?<RRFInput
                                id="activity"
                                type="text"
                                defaultValue={editInstance && editInstance.props.activity}
                                model=".activity" 
                            />:<RRFInput
                                id="activity"
                                model=".activity"
                                noResultsText="Please select concern type..."
                                type="dropdown"
                                menuItems={activityOptions}
                                defaultValue={editInstance && editInstance.props.activity}
                            /> }
                        </Col>
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col sm={4} xs={12} >
                        <Label sm={12} htmlFor="injuredPartyWorkFunction">Injured Party's Work Function:</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.incidentNotificationForm.injuredPartyWorkFunction} model="forms.incidentNotificationForm.injuredPartyWorkFunction" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="injuredParty"
                                placeholder="Enter injured party's work function..."
                                type="text"
                                model=".injuredPartyWorkFunction"
                                defaultValue={editInstance && editInstance.props.injuredPartyWorkFunction}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12} >
                        <Label required sm={12} htmlFor="injuryBodyPart">Injured Body Part:</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="injuryBodyPart"
                                type="dropdown"
                                menuItems={injuryBodyPartOptions}
                                model=".injuryBodyPart"
                                defaultValue={editInstance && editInstance.props.injuryBodyPart}
                            />
                        </Col>

                    </Col>
                    <Col sm={4} xs={12} >
                        <Label required sm={12} htmlFor="injuryClassification">Injury Classification:</Label>
                        <Col sm={12} className="input">
                            <RRFInput
                                id="injuryClassification"
                                type="dropdown"
                                menuItems={injuryClassificationOptions} model=".injuryClassification"
                                defaultValue={editInstance && editInstance.props.injuryClassification}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col sm={4} xs={12} className="mt5percent"> 
                        <Label sm={12} htmlFor="injuryDiagnosis">Injury Diagnosis:</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.incidentNotificationForm.injuryDiagnosis} model="forms.incidentNotificationForm.injuryDiagnosis" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="injuryDiagnosis"
                                placeholder="Enter injury diagnosis..."
                                type="textarea"
                                model=".injuryDiagnosis"
                                defaultValue={editInstance && editInstance.props.injuryDiagnosis}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12} className="mt5percent"> 
                        <Label sm={12} htmlFor="injurySummary">Injury Summary:</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.incidentNotificationForm.injurySummary} model="forms.incidentNotificationForm.injurySummary" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="injurySummary"
                                placeholder="Enter injury summary..."
                                type="textarea"
                                model=".injurySummary"
                                defaultValue={editInstance && editInstance.props.injurySummary}
                            />
                        </Col>
                    </Col>
                    <Col sm={4} xs={12} style={{ marginTop: '2.3%'}}> 
                        <Label sm={12} htmlFor="activityBeingPerformed">Activity Being Performed During Incident:</Label>
                        <VoiceRecognition labelText="" defaultValue={forms.incidentNotificationForm.activityBeingPerformed} model="forms.incidentNotificationForm.activityBeingPerformed" /> 
                        <Col sm={12} className="input">
                            <RRFInput
                                id="activityBeingPerformed"
                                placeholder="Enter activity being performed during the incident..."
                                type="textarea"
                                model=".activityBeingPerformed"
                                defaultValue={editInstance && editInstance.props.activityBeingPerformed}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="single-checkbox">
                    <Col sm={12} xs={12}>
                        <label className="checkbox-label" htmlFor="followUp">Investigative Report Needed?</label>
                        <RRFInput
                            id="followUp"
                            type="checkbox"
                            model=".followUpNeeded"
                            defaultValue={editInstance && editInstance.props.followUp}
                        />
                        <label htmlFor="nearMiss"></label>
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col /* smOffset={4} */ sm={6} xs={12}>
                        <Label sm={12} required={false} htmlFor="upload-images">Images:</Label>
                        <Col sm={12} className="input">
                            <FileInput
                                model=".incidentNotificationImages"
                                multiple={true}
                                id="upload-images"
                                defaultValue={editInstance && editInstance.props.imageUrls}
                            />
                        </Col>
                    </Col>
                    <Col sm={6} xs={12}>
                        <Label required={false} sm={12} htmlFor="upload-videos">Videos:</Label>
                        <Col sm={12} className="input">
                            <VideoInput
                                model=".incidentNotificationVideos"
                                multiple={true}
                                id="upload-videos"
                                defaultValue={editInstance && editInstance.props.videoUrls}
                            />
                        </Col>
                    </Col> 
                </Row>
            </fieldset>
            <Row className="show-grid">
                <div className="form-button" style={{ marginTop: '20px' }}> 
                    <Alert className="danger-alert" id="incident-notification-form" />
                    <Button style={{
                        color: 'rgb(84, 121, 175)',
                        border: '1px solid #5479AF',
                        backgroundColor: 'white',
                    }}
                        redirectTo="goBack" type="button" 
                        onClick={() => this.props.history?.goBack() }
                    >
                        <i className="fa fa-ban" aria-hidden="true"></i>
                        &nbsp;
                        Cancel
                    </Button>
                    <Button style={{
                        color: '#FFFFFF',
                        border: 'none',
                        backgroundColor: '#26A65B'
                    }}
                        type="button"
                        onClick={this.handleSubmit}
                    >
                        <i className={ editInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                            &nbsp;
                        { editInstance ? 'Update' : 'Submit' }
                    </Button>
                </div>
            </Row>
        </Form>;
    }

    getDesignations = () => {
        let designations = DesignationModel.list(); 
        const designationsOptions: { label: any; value: any; }[] = []; 
        designations.forEach((item: any) => {
            if(item.props.status===1)
                designationsOptions.push({ label: item.props.designation, value: item.props.id });
        }); 
        
        return designationsOptions;
    }

    getLocation = (position: { coords: { longitude: number, latitude: number } }) => {
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        });
        return position;
    }

    handleSubmit = () => { 
        let { timeOfIncident, injuredPartyIdNo, injuredPartyWorkFunction, team, injurySummary, injuryDiagnosis, injuryBodyPart, injuryClassification, dateOfIncident, groupName, projectName, concernType, activity, activityBeingPerformed, mobileLocation, locationName, followUpNeeded} = this.props.forms.incidentNotificationForm;  
        setLoading(this.identifier);
        const { props: { history, userUID, forms, editId } } = this; 
        const answerData = { reportDate: dateOfIncident, reportTime: timeOfIncident, locationString: mobileLocation, specifiedLocation: locationName, injuredPartyIdNo, team, injuredPartyWorkFunction, activityBeingPerformed, concernType, activity, injuryDiagnosis, injurySummary, injuryBodyPart, injuryClassification, };
        let answers = constructAnswers(answerData);  
        if (isFormEmpty({ dateOfIncident,timeOfIncident, locationName, projectName, groupName, injuredPartyIdNo, concernType, activity, injuryBodyPart, injuryClassification
        })) {
            setSuccess(this.identifier);
            showAlert(EMPTY_FORM_MESSAGE, 'incident-notification-form', 'danger'); 
            return;
        }   
        if(!editId && !this.state.showConfirmationModal && UserModel.checkUserAccess('incident_investigative_add') ) {
            setSuccess(this.identifier);
            this.setState({ 
                showConfirmationModal: !this.state.showConfirmationModal
            });
            return;
        }

        return submitIncidentReport(answers, userUID, followUpNeeded, dateOfIncident, history, groupName, projectName, forms, this.fillFollowUpForm, editId);
    }

    changeModalVisibility = () => {  
        this.setState({ 
            showConfirmationModal: !this.state.showConfirmationModal
        });
     //this.handleSubmit();
        /* const { editId } = this.props;
        if(editId) {
            this.handleSubmit();
        } else {
            const { specifiedLocation, injuredPartyIdNo, injuredPartyWorkFunction, team,
                injurySummary, injuryDiagnosis, injuryBodyPart, injuryClassification,
                groupName, projectName, concernType, activity, activityBeingPerformed
            } = this.props.forms.incidentNotificationForm;
            const submittedValues = {
                specifiedLocation, projectName, groupName, injuredPartyIdNo,
                team, concernType, activity, injuredPartyWorkFunction, injuryBodyPart, injuryClassification,
                injuryDiagnosis, injurySummary, activityBeingPerformed
            }; 
            if (isFormEmpty({  projectName, groupName,  injuredPartyIdNo, concernType,
                activity, injuryBodyPart, injuryClassification
            })) {
                setSuccess(this.identifier);
                showAlert(EMPTY_FORM_MESSAGE, 'incident-notification-form', 'danger');
                return;
            }
            this.setState({ 
                showConfirmationModal: !this.state.showConfirmationModal
            });
        } */
    }

    showModal = () => {
        return (
            <Modal className="confirmation-modal"
                show={this.state.showConfirmationModal}
                onHide={this.changeModalVisibility}>
                <Modal.Header closeButton>
                    <Modal.Title style={{color:'white'}}>Submit Incident Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you want to fill up a investigative form?
                    <div className="modal-buttons  " style={{ textAlign: 'center' }}>
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white',
                            padding: '0px 10px',
                            width: 'auto'
                        }}
                            onClick={() => {
                                this.changeModalVisibility();
                                this.handleSubmit();
                            }}

                        >No, Submit This Only</Button>
                        <Button style={{
                            border: 'none',
                            backgroundColor: '#26a65b',
                            padding: '0px 10px',
                            width: 'auto'
                        }}
                            onClick={() => {
                                this.changeModalVisibility();
                                this.fillFollowUpForm = true;
                                this.handleSubmit();
                            }}
                        >Yes, Fill Investigative</Button> 
                    </div>
                </Modal.Body>
            </Modal >
        );
    }

    renderContent = () => {
        return (
            <Grid className="incident-notification" style={{ width: '100%' }}>
                <Async
                    identifier={this.identifier} 
                    loader={this.renderForm(true)}
                    error={<div></div>}
                    content={this.renderForm(false)}
                />

            </Grid >
        );
    }

    render() {
        return (
            <BasePage className="safeconnect-form">
                {/* <ConfirmationModal id="concern-types" /> */}
                <Async
                    identifier="IncidentNotification"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const groupInstances = GroupModel.list();
    const userId = state.login.get('userId');
    const userInstance = UserModel.get(userId); 
    const designationInstance = DesignationModel.list();
    const editInstance = IncidentNotificationModel.get(ownProps.match.params.id); 
    const editId = ownProps.match.params.id; 
    return {
        groupInstances,
        userInstance, designationInstance, 
        userId,
        forms: state.forms,
        userUID: state.login.get('userUID'),
        company: state.login.get('company'),
        activityOptions: state.formSummary.get('activityMenuItems'), editInstance, editId
    };
}

export const IncidentNotificationForm = withRouter(connect<IIncidentNotificationFormProps, any, any>(mapStateToProps)(IncidentNotificationFormImpl));
