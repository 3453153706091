import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IHistory, ISelectOptions } from '../../interfaces';
import { GroupModel, IGroupModelProps } from '../../model/GroupModel';
import { UserModel } from '../../model/UserModel';
import { getAllUsers } from '../../services/userService';
import { Async } from '../reusableComponents/Async';
import { Button } from '../reusableComponents/FormComponents/Button';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Label } from '../reusableComponents/FormComponents/Label';
import { Loader } from '../reusableComponents/Loader';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Form } from '../reusableComponents/FormComponents/Form';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';

export interface IGroupFormProps {
    userUID: string;
    groupName?: string;
    groupDesc?: string;
    groupMembers?: string;
    instance?: GroupModel;
    groupForms?: string;
    onSubmit?: (data: Object) => void;
    history?: IHistory;
    onCancel?: () => void;
}

export interface IGroupFormState {
    noOfImages: { name: string, image: string }[];
    groupFormOpt: ISelectOptions[];
    groupMemberOptions: Object[];
    defaultGroupMembers: string[];
    defaultGroupForms: string[]; 
}

export class GroupFormImpl extends React.PureComponent<IGroupFormProps, IGroupFormState> {

    constructor(props: IGroupFormProps | Readonly<IGroupFormProps>) {
        super(props);
        this.state = {
            groupMemberOptions: [], defaultGroupForms: [], defaultGroupMembers: [], noOfImages: [],
            groupFormOpt: [{ label: 'Daily', value: 'dailyForm' }, { label: 'Monthly', value: 'monthlyForm' }]
        };
    }

    noOfImages = [];

    static childContextTypes = {
        formModel: PropTypes.string
    };

    getChildContext() {
        return { formModel: 'forms.groupForm' };
    }

    componentWillMount() {
        const { groupName } = this.props;
        if (groupName) {
            this.buttonLabel = 'Update Group';
        }
    }

    promise = async () => {
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        this.getOptions();
        let groupMembersId: any = [];
        (this.props.instance?.props.members || []).forEach((item: any) => {
            groupMembersId.push(item.userUID)
        } )
        this.setState({defaultGroupMembers: groupMembersId })
    }

    groupFamily: any = [];
    groupMembers: any = [];
    buttonLabel = 'Add Group';

    getOptions = () => {
        const users = UserModel.list(); 
        let firstNames: any[] = [];
        users.forEach( (user:any) => {
            let firstName = user.props.firstName;
            firstNames.push(firstName);
            if (firstNames.indexOf(firstName) > -1) {
                firstName = user.props.firstName + ' ' + user.props.lastName;
            }
            this.groupMembers.push({ label: firstName, value: user.props.userUID.toString() });
        });
    }

    handleSubmit = async ({ groupMembers, groupForms, groupDesc, groupName }: IGroupModelProps) => {
        const { props: { userUID } } = this;
        const submittedValue = {
            groupCreator: userUID,
            groupName,
            groupDesc,
            groupMembers: groupMembers && groupMembers.split(','),
        };
        return this.props.onSubmit && this.props.onSubmit(submittedValue);
    }

    renderContent = () => {
        const { props: { groupDesc, groupName }, } = this;  
        return (
            <div>
                <Row className="show-grid">
                    <Label required htmlFor="groupName">Group Name:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            id="groupName"
                            type="text"
                            placeholder="Enter group name..."
                            model=".groupName"
                            defaultValue={groupName}
                        />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Label required htmlFor="groupDesc" >Group Description:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            id="groupDesc"
                            type="text"
                            placeholder="Enter group description..."
                            model=".groupDesc"
                            defaultValue={groupDesc}
                        />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Label required htmlFor="groupMembers" >Group Members:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            id="groupMembers"
                            multi={true}
                            model=".groupMembers"
                            type="dropdown"
                            placeholder="Select group members..."
                            menuItems={this.groupMembers}
                            defaultValue={this.state.defaultGroupMembers}
                        />
                    </Col>
                </Row>
                {/* {instance ? <Row className="show-grid">
                    <Label htmlFor="groupForm">Group Forms:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            id="groupForm"
                            multi
                            type="dropdown"
                            placeholder="Select group forms..."
                            menuItems={groupFormOpt}
                            model=".groupForms"
                            defaultValue={groupForms}
                        />
                    </Col>
                </Row> : ''} */}

                <Row className="show-grid">
                    <div className="form-button" style={{ marginTop: '50px' }} >
                        <Button
                            style={{
                                color: '#5479AF',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white'
                            }}
                            onClick={() => this.props.history?.goBack() }
                            redirectTo="home" type="reset" >
                            <i className="fa fa-ban" aria-hidden="true"></i>
                            &nbsp;
                            Cancel
                        </Button>
                        <Button
                            style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26a65b'
                            }} >
                            {groupName ? <i className="fa fa-refresh" aria-hidden="true"></i> :
                                <i className="fa fa-plus" aria-hidden="true"></i>}
                            &nbsp;
                            {this.buttonLabel}
                        </Button>
                    </div>
                </Row>
            </div>
        );
    }

    renderForm = () => { 
        return (
            <Form
                loader={<SubmissionLoader />}
                model="forms.groupForm"
                onSubmit={this.handleSubmit}
            >
                {this.renderContent()}
            </Form>
        );

    }

    render() {
        return (
            <Async
                identifier="GroupForm"
                promise={this.promise}
                loader={<Loader />}
                error={<ErrorPage />}
                content={this.renderForm()}
            />
        );
    }
}

export function mapStateToProps(state: any) {
    const userUID = state.login.get('userUID');
    return { userUID };
}
export const GroupForm = withRouter(connect<IGroupFormProps, any, any>(mapStateToProps)(GroupFormImpl)); 
