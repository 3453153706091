import {Cell} from '../reusableComponents/ChartComponents/Cell';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Col, Row, DropdownButton, Dropdown} from 'react-bootstrap';
import {IHistory} from '../../interfaces';
import {showDelConfirmModal} from '../../actions/modalActions';
import {getPageItemNumber} from '../../utils/generalUtils';
import {UserModel} from '../../model/UserModel';
import {showCloseCallFollowUpListModal} from '../../actions/modalActions';
import {setLoading} from '../../actions/loadingActions';
import {getAllFollowUpForms} from '../../services/closeCallService';
import {UtilityDamageReportModel} from "../../model/UtilityDamageReportModel";
import {format} from "date-fns"; 
const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: UtilityDamageReportModel;
    style?: React.CSSProperties;
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderRow = () => {
        const {index, instance, style} = this.props;
        if (!instance) {
            return <div/>;
        }
        const {props: {date, id, username, answers: {dateOfDamage, jobNo}}}: any = instance;
        return <Row
            className="report-row"
            style={style}
        >
            <Col sm={1}>
                {<DropdownButton title="" id="bg-vertical-dropdown-3">
                    <MenuItem eventKey="1"
                              onClick={() => this.props.history?.push(`/UtilityDamageReportDetail/${instance.props.id}`)}
                              className="item"><i className="fa fa-eye" aria-hidden="true"/> View</MenuItem>
                    <MenuItem eventKey="2"
                              onClick={() => this.onEditAttribute(instance.props)}
                              className="item"><i className="fa fa-pencil" aria-hidden="true"/> Edit</MenuItem>
                    { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="3"
                            onClick={() => this.onDeleteAttribute('archive')}
                            className="item"><i className="fa fa-archive" aria-hidden="true"></i> Archive</MenuItem> }
                    { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="4"
                            onClick={() => this.onDeleteAttribute('delete')}
                            className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                </DropdownButton>}
                <span className="serial-no m-l10">{getPageItemNumber(index || 0, this.props.currentPage)}</span>
            </Col>
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{date || 'NA'}</Cell>
            <Cell sm={3} onClick={() => this.onViewAttribute(id)}>{username || 'NA'}</Cell>
            <Cell sm={2}
                  onClick={() => this.onViewAttribute(id)}>{format(new Date(dateOfDamage), 'MM/dd/yyyy') || 'NA'}</Cell>
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{jobNo || 'NA'}</Cell>
            {/*    <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{projectName || 'NA'}</Cell>
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{groupName || 'NA'}</Cell>*/}
            {/*   <Col className="clickable-list-item" sm={2} onClick={() => this.getFollowUpList(instance.props)}>
               // FIXME:// originally have 'totalClosedFollowUp', need to check with sampath
                <Button
                    className="closecall-followup-button"> {totalOpenFollowUp || '0'}/{totalFollowUp || '0'}</Button>
            </Col>*/}
        </Row>;
    }

    getFollowUpList(instance: any) {
        if (!UserModel.checkUserAccess('follow_up_list'))
            return;
        let modalInstance: any = [];
        modalInstance['closeCallId'] = instance['id'];
        modalInstance['followUpFor'] = instance;
        showCloseCallFollowUpListModal(modalInstance);
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['id']);
    }

    onDeleteAttribute(actionType: string) {
        const {instance} = this.props;
        instance && showDelConfirmModal('delete-confirmation', 'utilityDamageReport', instance.props, actionType);
    }

    onEditAttribute(instance: any) {
        this.props.history?.push(`/utilityDamageReport/edit/${instance.key}`);
    }

    onViewAttribute(id: string) {
        if (!UserModel.checkUserAccess('o&i_view'))
            return;
        this.props.history?.push(`/UtilityDamageReportDetail/${id}`);
    }

    render() {
        return this.renderRow();
    }

}

export function mapStateToProps(state: any) {
    return {}
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
