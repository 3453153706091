import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../interfaces';
import { ProjectModel } from '../../model/ProjectModel';
import { UserModel } from '../../model/UserModel';
import { getAllProjects } from '../../services/projectService';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { ConfirmationModal } from './ConfirmationModal';
import { ListItem } from './ListItem';

export interface IProjectListPageProps {
    projectInstance: ProjectModel[];
    history?: IHistory;
}

export interface IProjectListPageState {
    showDetails: boolean;
    formId: string;
}

export class ProjectListPageImpl extends React.PureComponent<IProjectListPageProps, IProjectListPageState> {
    constructor(props: IProjectListPageProps | Readonly<IProjectListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }

    promise = async () => { 
        await getAllProjects('projectList'); 
        return null;
    }

    renderAddProjectButton = () => {
        const { projectInstance } = this.props;
        if (!projectInstance) {
            return;
        } 

        return <button className="add-new-report"
            onClick={() => { this.props.history?.push('/teams/create'); }}
        > + </button>;
    }

    renderFormList = () => {
        const { projectInstance } = this.props;
        return <Async
            identifier="ProjectListPage"
            promise={this.promise}
            error={<ErrorPage />}
            loader={<Loader />}
            content={
                <div className="project-list-wrapper">
                    <ListPage
                        pageHeading="Teams"
                        instances={projectInstance}
                        rowHeadings={['Team Creator', 'Team Name', 'Date Created']}
                        listItemComponent={ListItem}
                        isSearchable={false}
                        searchableBy={['projectName']}
                        searchPlaceHolder="Enter team name to search..."
                        sm={[4, 4, 3]}
                        emptyInstancesMessage="No teams found."
                    />
                    { UserModel.checkUserAccess('project_add') && this.renderAddProjectButton()}
                </div>
            }
        />;
    }

    render() {
        return (
            <BasePage>
                <AlertModal path="/teams" id="delete-project-success" />
                <ConfirmationModal id="delete-project" />
                {this.renderFormList()}
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const projectInstance = ProjectModel.list(state);
    return {
        projectInstance,
    };
}

export const ProjectListPage =
    withRouter(connect<IProjectListPageProps, any, any>(mapStateToProps)(ProjectListPageImpl)); 
