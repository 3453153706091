import { IHistory } from '../../interfaces';
import { ConfirmationModal } from './ConfirmationModal';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GroupModel } from '../../model/GroupModel';
import { UserModel } from '../../model/UserModel';
import { getAllGroups } from '../../services/groupService';
import { getAllUsers } from '../../services/userService';
import { BasePage } from '../reusableComponents/BasePage';
import { ListPage } from '../reusableComponents/ListPage';
import { ListItem } from './ListItem';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { Async } from '../reusableComponents/Async';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Loader } from '../reusableComponents/Loader';

export interface IGroupListPageProps {
    groupInstances: GroupModel[];
    history?: IHistory;
}

export interface IGroupListPageState {
    showDetails: boolean;
    formId: string;
}

export class GroupListPageImpl extends React.PureComponent<IGroupListPageProps, IGroupListPageState> {
    constructor(props: IGroupListPageProps | Readonly<IGroupListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }

    promise = async () => {
        /* if (GroupModel.list().length <= 0) {
            await getAllGroups();
        } */
        await getAllGroups('groupList');
        if (UserModel.list().length <= 1) {
            await getAllUsers();
        }
        return null;
    }

    handleClick = (formId: string) => {
        this.props.history?.push(`/groups/${formId}`);
    }

    renderFormList = () => {
        const { groupInstances } = this.props;
        return <Async
            identifier="GroupListPage"
            loader={<Loader />}
            promise={this.promise}
            error={<ErrorPage />}
            content={<div className="group-list-wrapper">
                <ListPage
                    pageHeading="Groups"
                    instances={groupInstances}
                    rowHeadings={['Group Creator', 'Group Name', 'Date Created']}
                    listItemComponent={ListItem}
                    isSearchable={false}
                    searchableBy={['groupName']}
                    searchPlaceHolder="Enter group name to search..."
                    emptyInstancesMessage="No Groups found."
                    sm={[4, 4, 3]}
                />
                { UserModel.checkUserAccess('group_add') && this.renderAddGroupButton() }
            </div>}
        />;
    }

    renderAddGroupButton = () => { 
        /* if (!UserModel.isAdmin()) {
            return;
        } */
        return (<button className="add-new-report"
            onClick={() => { this.props.history?.push('/groups/create'); }}
        >
            +
        </button>);
    }

    render() {
        return (
            <BasePage>
                <AlertModal path="/groups" id="delete-group-success" />
                <ConfirmationModal id="delete-group" />
                {this.renderFormList()}
            </BasePage>
        );
    }
}

export function mapStateToProps(state: any) {
    const groupInstances = GroupModel.list(state);
    return {
        groupInstances,
    };
}

export const GroupListPage = withRouter(connect< IGroupListPageProps, any, any>(mapStateToProps)(GroupListPageImpl)); 
