import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Col, Row, DropdownButton, Dropdown, Button} from 'react-bootstrap';
import {IHistory} from '../../../interfaces';
import {MocPermanentModel} from "../../../model/MOCModel";
import {showDelConfirmModal} from '../../../actions/modalActions';
import {getPageItemNumber, getPermanentDeviation} from '../../../utils/generalUtils';
import {UserModel} from '../../../model/UserModel';
import {showCloseCallFollowUpListModal} from '../../../actions/modalActions';
import {setLoading} from '../../../actions/loadingActions';
import {getAllFollowUpForms} from '../../../services/closeCallService';
import {Cell} from '../../reusableComponents/ChartComponents/Cell';

const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: MocPermanentModel;
    style?: React.CSSProperties;
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;
    approvalStatus:any = '';
    renderRow = () => {
        const {index, instance, style} = this.props;
        if (!instance) {
            return <div/>;
        }
        const {props: {date, id, answers: {groupName, originator}}}: any = instance;
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
        }
        return <Row
            className="report-row"
            style={style}
        >
            <Col sm={1}>
                {<DropdownButton title="" id="bg-vertical-dropdown-3">
                    <MenuItem eventKey="1"
                              onClick={() => this.props.history?.push(`/moc-permanent-deviation/detail/${instance.props.id}`)}
                              className="item"><i className="fa fa-eye" aria-hidden="true"/> View</MenuItem>
                    <MenuItem eventKey="2"
                              onClick={() => this.onEditAttribute(instance.props)}
                              className="item"><i className="fa fa-pencil" aria-hidden="true"/> Edit</MenuItem> 
                    { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="3"
                            onClick={() => this.onDeleteAttribute('archive')}
                            className="item"><i className="fa fa-archive" aria-hidden="true"></i> Archive</MenuItem> }
                    { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="4"
                            onClick={() => this.onDeleteAttribute('delete')}
                            className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                </DropdownButton>}
                <span className="serial-no m-l10">{getPageItemNumber(index || 0, this.props.currentPage)}</span>
            </Col>
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{ date || 'NA'}</Cell> 
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{originator || 'NA'}</Cell> 
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{groupName || 'NA'}</Cell>
            <Col sm={2} onClick={() => this.onViewAttribute(id)}>{this.getStatusCount(answer)}</Col> 
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{this.approvalStatus}</Cell> 
        </Row>;
    }
    getStatusCount(answer: any) {
        //let completed = answer.productionManagerApprovedDate?5:answer.operationsManagerApprovedDate?4:answer.safetyManagerApprovedDate?3:answer.productionSupervisorApprovedDate?2:answer.safetySupervisorApprovedDate?1:0;
        /* let completed = '';
        let statusClass = '';
        //return <span>{`${completed}/5`}</span>;
        if(answer.productionManagerApprovedDate) {
            if(answer.productionManagerApproval==='0') 
                statusClass = 'danger'; 
            else
                statusClass = 'success'; 
            completed = '5';
        } else if(answer.operationsManagerApprovedDate) {
            if(answer.operationsManagerApproval==='0') 
                statusClass = 'danger'; 
            else
                statusClass = 'default'; 
            completed = '4';
        } else if(answer.safetyManagerApprovedDate) {
            if(answer.safetyManagerApproval==='0') 
                statusClass = 'danger'; 
            else
                statusClass = 'default'; 
            completed = '3';
        } else if(answer.productionSupervisorApprovedDate) {
            statusClass = 'default';  completed = '2';
        } else if(answer.safetySupervisorApprovedDate) {
            statusClass = 'default';  completed = '1';
        } else {
            statusClass = 'default';  completed = '0';
        }
        if(statusClass==='default') {
            this.approvalStatus = ' - ';
        } else if(statusClass==='success') {
            this.approvalStatus = 'Approved';
        } else if(statusClass==='danger') {
            this.approvalStatus = 'Denied';
        } */
        const {completed, approvalStatus, statusClass } = getPermanentDeviation(answer);
        this.approvalStatus = approvalStatus;
        return  <Button className={`moc-status-count ${statusClass}`} > {completed}/{'5'}</Button> 
    }

    getFollowUpList(instance: any) {
        if (!UserModel.checkUserAccess('follow_up_list'))
            return;
        let modalInstance: any = [];
        modalInstance['closeCallId'] = instance['id'];
        modalInstance['followUpFor'] = instance;
        showCloseCallFollowUpListModal(modalInstance);
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['id']);
    }

    onDeleteAttribute(actionType: string) {
        const {instance} = this.props;
        instance && showDelConfirmModal('delete-confirmation', 'MOC-Permanent Deviation Report', instance.props, actionType);
    }

    onEditAttribute(instance: any) {
        this.props.history?.push(`/moc-permanent-deviation/edit/${instance.key}`);
    }

    onViewAttribute(id: string) {
        if (!UserModel.checkUserAccess('o&i_view'))
            return;
        this.props.history?.push(`/moc-permanent-deviation/detail/${id}`);
    }

    render() {
        return this.renderRow();
    }

}

export function mapStateToProps(state: any) {
    return {}
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
