import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../interfaces';
import { OIModel } from '../../model/OIModel';
import { getAllOIForms, getOIFormSummaryData } from '../../services/oiformService';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { PieChart } from '../reusableComponents/ChartComponents/PieChart';
import { PieChartLegends } from '../reusableComponents/ChartComponents/PieChartLegends';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { Pagination } from '../reusableComponents/Pagination';
import { Title } from '../reusableComponents/Title';
import { ListItem } from './ListItem'; 
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal'; 
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { UserModel } from '../../model/UserModel';
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { setSuccess, setLoading } from '../../actions/loadingActions';
import { dispatchCommonFilter } from '../../utils/generalUtils';
declare var require: any;
const queryString = require('query-string');

export interface IOIFormListPageProps {
    oiFormInstances: OIModel[];
    history?: IHistory;
    nearMiss?: number;
    positiveObservations?: number;
    negativeObservations?: number;
    concernTypes: {
        Safety: number,
        Health: number,
        Environment: number,
        Security: number,
        Other: number
    };
    /* concernTypes?: any; */
    security?: number;
    totalPageCount?: number;
    userId: string;
    formSummaryData: any;
    forms: IForms;
    axiosPreviousToken: any; 
       
}  
export interface IOIFormListPageState {
    showDetails: boolean;
    formId: string;
}

export class OIFomListPageImpl extends React.PureComponent<IOIFormListPageProps, IOIFormListPageState> {
    constructor(props: IOIFormListPageProps | Readonly<IOIFormListPageProps>) {
        super(props);
        this.state = { showDetails: false, formId: '' };
    }
    sortListing = { 0: 'date', 1: 'username', 2:'observation', 3: 'actionTaken', 4: 'nearMiss' };
    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => { 
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await dispatchCommonFilter(urlParams); 
        await this.getReportData(urlParams.sortingIndex);
        return null;
    }

    getReportData = async (sortIndex='false') => { 
        setLoading('AllListPage'); 
        let urlParams = queryString.parse(this.props.history?.location['search']) || {}; 
        if(sortIndex==='false') {
            await getOIFormSummaryData(this.props.forms); 
        } else { 
            urlParams.sortIndexField = this.sortListing[sortIndex];
        }
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllOIForms(urlParams, this.props.forms);  
        setSuccess('AllListPage');
    } 

    renderFormList = () => {
        const { oiFormInstances } = this.props; 
        return <div className="oi-list-wrapper" >
            <ListPage
                style={{ minHeight: '50vh', padding: '0px 0px 0px 0px' }}
                titleStyle={{ padding: '0px 70px' }}
                pageHeading="O&amp;I Report List"
                instances={oiFormInstances}
                rowHeadings={['Date Submitted', 'Observer', 'Observation', 'Action Taken', 'Near Miss','Follow-up Status']}
                listItemComponent={ListItem}
                isSearchable={true}
                searchableBy={['actionTaken', 'username', 'observation', 'date', 'groupName', 'projectName','specifiedLocation']}
                searchPlaceHolder="Enter action taken, observation, project, group, observer to search..."
                emptyInstancesMessage="No O&amp;I reports found."
                sm={[2, 2, 2, 2, 2, 1]}
                currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                /* filterComponent={<CommonFilter onApply={this.getReportData} forms={this.props.forms}/> } */
                //rowSortings={[0,1,2,3,4]}
                rowSortings={this.sortListing} 
                promise={(sortIndex) => this.getReportData(sortIndex)}
                forms={this.props.forms}
                history={this.props.history }
            />
            { UserModel.checkUserAccess('o&i_add') && this.renderAddNewFormButton()}
            { UserModel.checkUserAccess('o&i_report_summary') && this.renderSummaryPageButton()}
            <Pagination
                history={this.props.history}
                promise={(pageNumber) => this.promise(pageNumber)}
                totalCount={this.props.totalPageCount}
                Model={OIModel as any} 
                identifier="AllListPage"
            />
        </div>;
    }

    renderSummaryPageButton = () => {
        return (
            <button className="summary-page"
                onClick={() => { this.props.history?.push('/oi-reports/summary'); }}
            >
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </button>
        );
    }

    renderAddNewFormButton = () => { 
        return (
            <button className="add-new-report"
                onClick={() => { this.props.history?.push('/oi-reports/create'); }}
            >
                +
            </button>
        );
    }

    renderPieChart = () => { 
        const { concernTypes } = this.props.formSummaryData;
        const data: Array<any> = [];
        if(concernTypes) {
            if ("Safety" in concernTypes) 
                data.push({ name: 'Safety', value: concernTypes['Safety'] });
            if ("Health" in concernTypes)
                data.push({ name: 'Health', value: concernTypes['Health'] });
            if ("Environment" in concernTypes)
                data.push({ name: 'Environment', value: concernTypes['Environment'] });
            if ("Security" in concernTypes) 
                data.push({ name: 'Security', value: concernTypes['Security'] });
            for (let key in concernTypes) {
                if(key!=='Safety' && key!=='Health' && key!=='Environment' && key!=='Security' ) {
                    data.push({ name: key, value: concernTypes[key] });
                }
            }
        }
        return (
            <PieChart
                data={data}
                innerRadius={60} 
                colorArray={['FFBECE', 'B0D9FF', 'FFF6BE', 'B3D9AD', 'F2BD91', 'C0F5FA', 'C9FF9C', 'A9DEDC', 'AFA9FC', 'EDA28E']}
                paddingAngle={1}
            />
        );
    }

    renderContent = () => {
        if(!this.props.formSummaryData) {
            return <></>
        }
        const { concernTypes } = this.props.formSummaryData; 
        const legendNames: Array<any> = [];
        if(concernTypes) {
            if ("Safety" in concernTypes) 
                legendNames.push('Safety');
            if ("Health" in concernTypes) 
                legendNames.push('Health');
            if ("Environment" in concernTypes)
                legendNames.push('Environment');
            if ("Security" in concernTypes)
                legendNames.push('Security'); 
            for (let key in concernTypes) { 
                if(key!=='Safety' && key!=='Health' && key!=='Environment' && key!=='Security' ) 
                    legendNames.push(key);
            }
        }
        const legendColors = ['FFBECE', 'B0D9FF', 'FFF6BE', 'B3D9AD', 'F2BD91', 'C0F5FA',
        'C9FF9C', 'A9DEDC', 'AFA9FC', 'EDA28E'];
        return (
            <Grid className="oi-report-list" style={{ width: '100%', padding: '20px' }}>
                <Row className="show-grid" style={{ margin: 'auto' }}>
                    <Title text="Observation and Intervention"></Title>
                    <h4 style={{ color: 'white', textAlign: 'center', margin: '10px 0px 0px 0px' }}>
                        Concern Types
                    </h4>
                    <div className="chart list-page-chart">
                        <Col sm={7} className="pie circle" style={{ height: '253px', width: '300px', margin: 'auto' }}>
                            {this.renderPieChart()}
                        </Col>
                        <PieChartLegends
                            legendNames={legendNames}
                            legendColors={legendColors}
                        />
                    </div>
                </Row >
                <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} />
                <ExcelExport formType="oiForm" fileName="OI Report" />
                {this.renderFormList()}
                {/* <Async
                    identifier="reportList" 
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderFormList()}
                />  */}
            </Grid>
        );
    }
    render() {
        return (
            <BasePage className="extra-wide-list-page" >
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Async
                    identifier="OIFormSummary"
                    promise={this.promise}
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    const oiFormInstances = OIModel.list(state);
    const userId = state.login.get('userId');
   /*  if (!state.formSummary.get('oiFormSummary')) {
        return {
            oiFormInstances,
            userId
        };
    } */
    const { formSummary } = state;
    const formSummaryData = formSummary.get('oiFormSummary');  
    return {
        nearMiss: formSummary.get('oiFormSummary')?.nearMiss,
        positiveObservations: formSummary.get('oiFormSummary')?.positive,
        negativeObservations: formSummary.get('oiFormSummary')?.negative,
        concernTypes: formSummary.get('oiFormSummary')?.concernTypes,
        totalPageCount: state.miscellaneous.get('listCount'),
        oiFormInstances,
        formSummaryData,
        userId, forms: state.forms,
        axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'),
    };
}

export const OIFormListPage = withRouter(connect<IOIFormListPageProps, any, any>(mapStateToProps)(OIFomListPageImpl));
