import * as React from 'react';
import { Container as Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory, IForms } from '../../interfaces';
import { OfficeSafetyModel } from '../../model/OfficeSafetyModel';
import { getAllOfficeSafetyForm } from '../../services/officeSafetyFormService';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { ListPage } from '../reusableComponents/ListPage';
import { Loader } from '../reusableComponents/Loader';
import { ListItem } from './ListItem';
import { Pagination } from '../reusableComponents/Pagination';
import { DeleteConfirmationModal } from '../reusableComponents/DeleteConfirmationModal';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { UserModel } from '../../model/UserModel';
import { ExcelExport } from '../reusableComponents/ExcelExport';
import { CommonFilter } from '../reusableComponents/FilterComponents/CommonFilter';
import { setLoading, setSuccess } from '../../actions/loadingActions';
import { dispatchCommonFilter } from '../../utils/generalUtils';
declare var require: any;
const queryString = require('query-string'); 

export interface IOfficeSafetyListPageProps {
    officeSafetyInstances: OfficeSafetyModel[];
    history?: IHistory;
    totalPageCount: number;
    forms?: IForms;
    axiosPreviousToken: any;
}

export class OfficeSafetyListPageImpl extends React.PureComponent<IOfficeSafetyListPageProps, {}> {
    constructor(props: IOfficeSafetyListPageProps | Readonly<IOfficeSafetyListPageProps>) {
        super(props);
    } 
    sortListing = { 0: 'date', 1: 'username', 2:'projectName', 3: 'groupName' };

    promise = async (pageNumber = queryString.parse(this.props.history?.location['search']).pageNumber || 1) => { 
        let urlParams = queryString.parse(this.props.history?.location['search']) || [];  
        await dispatchCommonFilter(urlParams);
        await this.getReportData(urlParams.sortingIndex)
        return null;
    }
    
    getReportData = async (sortIndex='false') => {  
        setLoading('AllListPage');   
        let urlParams = queryString.parse(this.props.history?.location['search']) || {}; 
        if(sortIndex!=='false') {
            urlParams.sortIndexField = this.sortListing[sortIndex];
        } 
        urlParams.axiosPreviousToken = this.props.axiosPreviousToken;
        await getAllOfficeSafetyForm(urlParams, this.props.forms);  
        setSuccess('AllListPage');
    } 

    renderAddNewFormButton = () => {
        const { history } = this.props;
        return (
            <button className="add-new-report"
                onClick={() => { history?.push('/office-safety-reports/create'); }}
            >
                +
            </button>
        );
    }

    renderSummaryPageButton = () => {
        return (
            <button className="summary-page"
                onClick={() => { this.props.history?.push('/office-safety-reports/summary'); }}
            >
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
            </button>
        );
    }
    renderReportList = () => {
        const { officeSafetyInstances } = this.props; 
        return (
            <>
                <ListPage
                    pageHeading="Office Safety Report List"
                    instances={officeSafetyInstances}
                    rowHeadings={['Date Submitted','Observer','Team Name', 'Group Name', 'Follow-up Status' ]}
                    listItemComponent={ListItem}
                    isSearchable={true}
                    searchableBy={[ 'date', 'username', 'projectName', 'groupName','locationName']}
                    searchPlaceHolder="Enter project, group, observer to search..."
                    emptyInstancesMessage="No Office Safety report found."
                    sm={[2, 3, 2, 2, 2]}
                    currentPage={queryString.parse(this.props.history?.location['search']).pageNumber || 1}
                    rowSortings={this.sortListing} 
                    promise={(sortIndex) => this.getReportData(sortIndex)}
                    forms={this.props.forms}
                    history={this.props.history }
                />
                { UserModel.checkUserAccess('office_safety_add') && this.renderAddNewFormButton()}
                { UserModel.checkUserAccess('office_safety_report_summary') && this.renderSummaryPageButton()}
                <Pagination
                    history={this.props.history}
                    promise={(pageNumber) => this.promise(pageNumber)}
                    totalCount={this.props.totalPageCount}
                    Model={OfficeSafetyModel as any}
                    identifier="OfficeSafetyFormReports"
                />
            </>
        )
    }
    renderContent = () => {
        return <div className="office-safety-wrapper">  
            <CommonFilter onApply={this.getReportData} forms={this.props.forms} history={this.props.history} />
            <ExcelExport formType="oiOfficeForm" fileName="Office OI Report" style={{paddingTop: '17px'}} />
            {this.renderReportList()}
            {/* <Async
                identifier="reportList"
                promise={this.promise}
                loader={<Loader />}
                error={<ErrorPage />}
                content={this.renderReportList()}
            />  */}
        </div>;
    }

    render() {
        return (
            <BasePage className="wide-list-page" > 
                <DeleteConfirmationModal id="delete-confirmation" />
                <AlertModal id="delete-success" />
                <Grid className="office-safety-report-list" style={{ width: '100%' }}>  
                    <Async
                        identifier="OfficeSafetyFormReports"
                        promise={this.promise}
                        loader={<Loader />}
                        error={<ErrorPage />}
                        content={this.renderContent()}
                    />
                </Grid>
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any) {
    const officeSafetyInstances = OfficeSafetyModel.list();
    return { officeSafetyInstances, totalPageCount: state.miscellaneous.get('listCount'), forms: state.forms, axiosPreviousToken: state.miscellaneous.get('axiosPreviousToken'), };
}

export const OfficeSafetyListPage = withRouter(connect<IOfficeSafetyListPageProps, any, any>
    (mapStateToProps)(OfficeSafetyListPageImpl)); 
