import {
    HIDE_CREATE_CUSTOM_NOTIFICATION_MODAL,
    HIDE_DELETE_CONCERN_MODAL,
    HIDE_GOLDEN_RULES_MODAL,
    HIDE_MAP_INPUT_MODAL,
    HIDE_MODAL,
    HIDE_PRINT_PREVIEW_MODAL,
    SHOW_CREATE_CUSTOM_NOTIFICATION_MODAL,
    SHOW_DELETE_CONCERN_MODAL,
    SHOW_GOLDEN_RULES_MODAL,
    SHOW_MAP_INPUT_MODAL,
    SHOW_MODAL,
    SHOW_PRINT_PREVIEW_MODAL,
    SHOW_FOLLOW_UP_FORM_MODAL,
    HIDE_FOLLOW_UP_FORM_MODAL,
    SHOW_CREATE_CLOSECALL_FOLLOWUP_MODAL,
    HIDE_CREATE_CLOSECALL_FOLLOWUP_MODAL,
    SHOW_CLOSECALL_FOLLOWUP_LIST_MODAL,
    HIDE_CLOSECALL_FOLLOWUP_LIST_MODAL, SHOW_COVID19_ASSESSMENT_MODAL, HIDE_COVID19_ASSESSMENT_MODAL,
    SHOW_CONFIRMATION_MODAL, HIDE_CONFIRMATION_MODAL, SHOW_AUDIT_INPUT_MODAL, HIDE_AUDIT_INPUT_MODAL, SHOW_CHECKLIST_MODAL, HIDE_CHECKLIST_MODAL, SHOW_MICROPHONE_MODAL, HIDE_MICROPHONE_MODAL
} from '../constants/actions';
import { dispatch } from '../utils/generalUtils';

export function showModal(id: string, modalHeading: string, instance: any) {
    return dispatch({
        type: SHOW_MODAL,
        id,
        instance,
        modalHeading
    });
}

export function hideModal() {
    return dispatch({
        type: HIDE_MODAL,
    });
}


export function showDelConfirmModal(id: string, componentType: string, instance: any, actionType?: string) {
    return dispatch({
        type: SHOW_MODAL,
        id,
        instance,
        componentType,
        actionType
    });
}

export function hideDelConfirmModal() {
    return dispatch({
        type: HIDE_MODAL,
    });
}

export function showPrintPreviewModal(id: string) {
    return dispatch({
        type: SHOW_PRINT_PREVIEW_MODAL,
        id
    });
}

export function hidePrintPreviewModal() {
    return dispatch({
        type: HIDE_PRINT_PREVIEW_MODAL,
    });
}

export function showGoldenRulesModal() {
    return dispatch({
        type: SHOW_GOLDEN_RULES_MODAL,
    });
}

export function hideGoldenRulesModal() {
    return dispatch({
        type: HIDE_GOLDEN_RULES_MODAL,
    });
}

export function showDeleteConcernModal(id: string, concernName: string, identifier: string) {
    return dispatch({
        type: SHOW_DELETE_CONCERN_MODAL,
        id,
        concernName,
        identifier
    });
}

export function hideDeleteConcernModal() {
    return dispatch({
        type: HIDE_DELETE_CONCERN_MODAL
    });
}

export function showCreateCustomNotificationModal() {
    return dispatch({
        type: SHOW_CREATE_CUSTOM_NOTIFICATION_MODAL,
        show: true
    });
}

export function hideCreateCustomNotificationModal() {
    return dispatch({
        type: HIDE_CREATE_CUSTOM_NOTIFICATION_MODAL,
        show: false
    });
}

export function showMapInputModal(id: any, markers: any, fullModelName: any, center: any) {
    return dispatch({
        type: SHOW_MAP_INPUT_MODAL,
        id, markers, fullModelName, center
    });
}

export function hideMapInputModal() {
    return dispatch({
        type: HIDE_MAP_INPUT_MODAL
    });
}

export function showFollowUpFormModal() {
    dispatch({
        type: SHOW_FOLLOW_UP_FORM_MODAL,
    });
}

export function hideFollowUpFormModal() {
    dispatch({
        type: HIDE_FOLLOW_UP_FORM_MODAL
    });
}
export function showCreateCloseCallFollowUpModal(instance: any) {
    return dispatch({
        type: SHOW_CREATE_CLOSECALL_FOLLOWUP_MODAL,
        show: true,
        instance
    });
}
export function hideCreateCloseCallFollowUpModal() {
    return dispatch({
        type: HIDE_CREATE_CLOSECALL_FOLLOWUP_MODAL,
        show: false
    });
}
export function showCloseCallFollowUpListModal(instance: any) {
    return dispatch({
        type: SHOW_CLOSECALL_FOLLOWUP_LIST_MODAL,
        show: true,
        instance
    });
}
export function hideCloseCallFollowUpListModal() {
    return dispatch({
        type: HIDE_CLOSECALL_FOLLOWUP_LIST_MODAL,
        show: false
    });
}

export function showCOVID19AssessmentModal() {
    return dispatch({
        type: SHOW_COVID19_ASSESSMENT_MODAL,
    });
}

export function hideCOVID19AssessmentModal() {
    return dispatch({
        type: HIDE_COVID19_ASSESSMENT_MODAL,
    });
}

export function showConfirmationModal(instance: any) {
    return dispatch({
        type: SHOW_CONFIRMATION_MODAL, instance
    });
}

export function hideConfirmationModal(instance?: any) {
    return dispatch({
        type: HIDE_CONFIRMATION_MODAL, instance
    });
}
export function showAuditEffectiveInputModal( fullModelName: any) {
    return dispatch({
        type: SHOW_AUDIT_INPUT_MODAL, fullModelName
    });
}
export function hideAuditEffectiveInputModal() {
    return dispatch({
        type: HIDE_AUDIT_INPUT_MODAL
    });
}

export function showChecklistProcessModal(id: string, instance: any ) {
    return dispatch({
        type: SHOW_CHECKLIST_MODAL, id, instance
    });
}

export function hideChecklistProcessModal() {
    return dispatch({
        type: HIDE_CHECKLIST_MODAL,
    });
}


export function showMicrophoneModal(model: any, defaultValue: any ) {
    return dispatch({
        type: SHOW_MICROPHONE_MODAL,
        model, defaultValue
    });
}

export function hideMicrophoneModal() {
    return dispatch({
        type: HIDE_MICROPHONE_MODAL
    });
}