export const LOCAL_STORAGE_KEY = 'SDUserInfo';
export const EMPTY_FORM_MESSAGE = 'Please fill all the fields.';
export const FORM_SUBMISSION_ERROR = 'Something went wrong please try again later..!';
export const DELETE_PROJECT_FAILED = 'Sorry, Unable to delete this project';
export const DELETE_GROUP_FAILED = 'Sorry, Unable to delete this group.';
export const DELETE_USER_FAILED = 'Sorry, Unable to delete this user.';
export const DELETE_SUCCESS = 'Form deleted successfully.';
export const DELETE_FAILED = 'Sorry, Unable to delete. Please try again later.';
export const ARCHIVE_SUCCESS = 'Form archived successfully.';
export const ARCHIVE_FAILED = 'Form archived successfully.';
export const UPDATE_USER_PASSWORD_FAILED = 'Sorry, Unable to Update the Password of the user';
export const LOGIN_FAILED = 'Invalid Credentials.';
export const DUPLICATE_GROUP = 'The group name already exist.';
export const DUPLICATE_PROJECT = 'The project name already exist.';
export const DUPLICATE_USER = 'The user already exist.';
export const TOTALSCOREERROR = 'Total score should be 1 to 100'; 
export const bodyParts = ['Hand', 'Head', 'Limb', 'Foot', 'Chest', 'Other'];
export const locations = ['SPI 1029', 'SPI 1033', 'BNSF', '7 Eleven', 'Other'];
export const VIDEOMAXSIZE = 52428800;//50MB //10485760 //100MB;
export const VIDEOALLOWEDEXE = ['mp4','m4p'];
export const IMAGEALLOWEDEXE = ['image/jpg','image/png','image/jpeg'];
export const FILESALLOWEDEXE = ['image/jpeg', 'image/png', 'image/jpg', 'video/mp4', 'video/x-m4v', 'application/pdf,application/msword', '.csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain,.doc', '.docx,.ppt', '.pptx,.txt', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation'];
export const INCIDENTINVESTIGATE_PREFIX = 'INC';
export const USERLEVELHIERARCHY = ['L1','L2','L3','L4','L5']; 
 
export const FOLLOWUPTITLES: any = {'closeCall':'Close Call','Close Call Checklist':'Close Call Checklist', 'Supervisor Checklist':'Supervisor Checklist', 'Manager Checklist':'Manager Checklist'};
export function followUpTitle(type: string) {  
    return FOLLOWUPTITLES[type] || type; 
}
export const OFFICESAFETYTITLES: any = {'onTheMoveComments':'On The Move','toolSelectionComments':'Facility/Equipment/Tool', 'manualHandlingComments':'Manual Handling', 'ergonomicsComments':'Ergonomics', 'bodyPositionComments':'Body Position', 'healthHygieneComments':'Health & Hygiene', 'securityComments':'Security', 'housekeepingComments':'HouseKeeping', 'envRecyclingComments':'Environment & Recycling'};
export function getOfficeSafetyCategoryTitle(type: string) {  
    return OFFICESAFETYTITLES[type] || type; 
}
export const AADPWD: any = {'spi':'f65015f2-ab1c-481c-9a07-371a2632ebe2','dart':'f65015f2-ab1c-481c-9a07-371a2632ebe2' };

export const GOOGLEAPIKEY = 'AIzaSyCj-VBhTeEWa5K0vgDbdM4KNhQGebCJZc0';
 
export const APPVERSION = '32';