import { Cell } from '../reusableComponents/ChartComponents/Cell';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row, DropdownButton, Dropdown, Button } from 'react-bootstrap'; 
import { IHistory } from '../../interfaces'; 
import { showDelConfirmModal } from '../../actions/modalActions';
import { getPageItemNumber } from '../../utils/generalUtils';
import { UserModel } from '../../model/UserModel';
import { showCloseCallFollowUpListModal } from '../../actions/modalActions';   
import { setLoading } from '../../actions/loadingActions'; 
import { getAllFollowUpForms } from '../../services/closeCallService'; 
import { AuditModel } from '../../model/AuditModel';
const MenuItem = Dropdown.Item;
export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: AuditModel;
    style?: React.CSSProperties; 
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;

    renderRow = () => {
        const { index, instance, style } = this.props;
        if (!instance) {
            return <div></div>;
        }
        const { props: { dateOfIncident, groupName, createdUserName, totalScore, serialNo, overallPercent } } = instance;
        return <Row
            className="report-row"
            style={style}
        >   
            <Col sm={1}>
                { ( UserModel.checkUserAccess('office_safety_edit') || UserModel.checkUserAccess('office_safety_view') || UserModel.checkUserAccess('office_safety_delete') ) && <DropdownButton title="" id="bg-vertical-dropdown-3">
                    { UserModel.checkUserAccess('office_safety_view') && <MenuItem eventKey="1"
                        onClick={() => this.onViewAttribute(instance.props)}
                        className="item"><i className="fa fa-eye" aria-hidden="true"></i> View</MenuItem> }
                    { UserModel.checkUserAccess('office_safety_edit') && <MenuItem eventKey="2"
                        onClick={() => this.onEditAttribute(instance.props)}
                        className="item"><i className="fa fa-pencil" aria-hidden="true"></i> Edit</MenuItem> } 
                    { UserModel.checkUserAccess('office_safety_delete') && <MenuItem eventKey="3"
                            onClick={() => this.onDeleteAttribute('archive')}
                            className="item"><i className="fa fa-archive" aria-hidden="true"></i> Archive</MenuItem> }
                    { UserModel.checkUserAccess('office_safety_delete') && <MenuItem eventKey="4"
                            onClick={() => this.onDeleteAttribute('delete')}
                            className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                </DropdownButton> }
                <span className="serial-no m-l10">{getPageItemNumber(index || 0, this.props.currentPage) }</span>
            </Col> 
            <Cell sm={2} onClick={() => this.onViewAttribute(instance.props)}>{dateOfIncident || 'NA'}</Cell>
            <Cell sm={2} onClick={() => this.onViewAttribute(instance.props)}>{createdUserName || 'NA'}</Cell> 
            <Cell sm={2} onClick={() => this.onViewAttribute(instance.props)}>{groupName || 'NA'}</Cell>
            <Cell sm={2} onClick={() => this.onViewAttribute(instance.props)}>{serialNo || 'NA'}</Cell>
            <Cell sm={2} onClick={() => this.onViewAttribute(instance.props)}>{`${totalScore}/${overallPercent}%` || 'NA'}</Cell>
            <Col sm={1} className="clickable-list-item" onClick={() => this.getFollowUpList(instance.props)} > 
                <Button className="closecall-followup-button" > {instance.props.totalClosedFollowUp || '0'}/{instance.props.totalFollowUp || '0'}</Button> 
            </Col>
        </Row>;
    } 
    getFollowUpList(instance: any) { 
        if(!UserModel.checkUserAccess('follow_up_list') )
            return;
        let modalInstance: any = [];  
        modalInstance['closeCallId'] = instance['id'];
        modalInstance['followUpFor'] = instance;
        showCloseCallFollowUpListModal(modalInstance);
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['id']);
    }
    onDeleteAttribute(actionType: string) {
        const { instance } = this.props;  
        instance && showDelConfirmModal('delete-confirmation', 'Audit/Inspection Form', instance.props, actionType);  
    }
    onEditAttribute(instance: any) {  
        this.props.history?.push(`/audit-form-edit/${instance.formId}/${instance.key}`);
    }
    onViewAttribute(instance: any) { 
        if(!UserModel.checkUserAccess('o&i_view') )
            return;
        this.props.history?.push(`/audit-form-view/${instance.formId}/${instance.key}`);
    }
    render() {
        return this.renderRow();
    }

}

export function mapStateToProps(state: any) { 
    return { 
    }
}
export const ListItem = withRouter(connect< IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
