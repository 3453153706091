import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Col, Row, DropdownButton, Dropdown} from 'react-bootstrap';
import {IHistory} from '../../interfaces';
import {PermitToWorkModel} from "../../model/PermitToWorkModel";
import {showDelConfirmModal} from '../../actions/modalActions';
import {getPageItemNumber} from '../../utils/generalUtils';
import {UserModel} from '../../model/UserModel';
import {showCloseCallFollowUpListModal} from '../../actions/modalActions';
import {setLoading} from '../../actions/loadingActions';
import {getAllFollowUpForms} from '../../services/closeCallService';
import {Cell} from '../reusableComponents/ChartComponents/Cell';

const MenuItem = Dropdown.Item;

export interface IListItemProps {
    history?: IHistory;
    index?: number;
    instance?: PermitToWorkModel;
    style?: React.CSSProperties;
    currentPage?: number;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {
    constructor(props: IListItemProps | Readonly<IListItemProps>) {
        super(props);
    }

    static defaultProps: IListItemProps;
    approvalStatus:any = '';
    renderRow = () => {
        const {index, instance, style} = this.props;
        if (!instance) {
            return <div/>;
        }
        const {props: { id }}: any = instance;
        let answer: any = {};
        if (instance && instance.props && instance.props.answers) {
            answer = instance.props.answers;
        } 
        return <Row
            className="report-row"
            style={style}
        >
            <Col sm={1}>
                {<DropdownButton title="" id="bg-vertical-dropdown-3">
                    <MenuItem eventKey="1" onClick={() => this.onViewAttribute(id)} className="item"><i className="fa fa-eye" aria-hidden="true"/> View</MenuItem>
                    <MenuItem eventKey="2" onClick={() => this.onEditAttribute(instance.props)} className="item"><i className="fa fa-pencil" aria-hidden="true"/> Edit</MenuItem>
                    { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="3"
                            onClick={() => this.onDeleteAttribute('archive')}
                            className="item"><i className="fa fa-archive" aria-hidden="true"></i> Archive</MenuItem> }
                    { UserModel.checkUserAccess('safety_walkthrough_delete') && <MenuItem eventKey="4"
                            onClick={() => this.onDeleteAttribute('delete')}
                            className="item"><i className="fa fa-trash" aria-hidden="true"></i> Delete</MenuItem> }
                </DropdownButton>}
                <span className="serial-no m-l10">{getPageItemNumber(index || 0, this.props.currentPage)}</span>
            </Col>
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{ instance.props.date || 'NA'}</Cell> 
            <Cell sm={1} onClick={() => this.onViewAttribute(id)}>{ (answer && answer.permitNo) || 'NA'}</Cell> 
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{ (answer && answer.locationName) || 'NA'}</Cell>  
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{ (answer && answer.taskDescription) || 'NA'}</Cell>  
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{ (answer && answer.PIReviewerName) || 'NA'}</Cell>  
            <Cell sm={2} onClick={() => this.onViewAttribute(id)}>{ (answer && answer.OIReviewerName) || 'NA'}</Cell>  
        </Row>;
    } 
    getFollowUpList(instance: any) {
        if (!UserModel.checkUserAccess('follow_up_list'))
            return;
        let modalInstance: any = [];
        modalInstance['closeCallId'] = instance['id'];
        modalInstance['followUpFor'] = instance;
        showCloseCallFollowUpListModal(modalInstance);
        setLoading('CloseCallFollowUpList');
        getAllFollowUpForms(instance['id']);
    }

    onDeleteAttribute(actionType: string) {
        const {instance} = this.props;
        instance && showDelConfirmModal('delete-confirmation', 'PTW Report', instance.props, actionType);
    }

    onEditAttribute(instance: any) {
        
        this.props.history?.push(`/permit-to-work/edit/${instance.key}`);
    }

    onViewAttribute(id: string) {
        if (!UserModel.checkUserAccess('o&i_view'))
            return;
        this.props.history?.push(`/permit-to-work/detail/${id}`);
    }

    render() {
        return this.renderRow();
    }

}

export function mapStateToProps(state: any) {
    return {}
}

export const ListItem = withRouter(connect<IListItemProps, any, any>(mapStateToProps)(ListItemImpl));
