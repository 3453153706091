import * as React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IHistory } from '../../../interfaces';
import { Button } from '../../reusableComponents/FormComponents/Button';
import { hideModal } from '../../../actions/modalActions';
import { updateReference } from '../../../services/miscellaniousServices';

export interface IChangePriorityModalProps {
    id?: string;
    modalHeading: string;
    showModal: boolean;
    modalID: string;
    reference: any;
    history?: IHistory;
}  

export interface IChangePriorityModalState {
    showModal: boolean; priority: Number;
}

export class ChangePriorityModalImpl extends React.PureComponent<IChangePriorityModalProps, IChangePriorityModalState> {
    constructor(props: IChangePriorityModalProps | Readonly<IChangePriorityModalProps>) {
        super(props); 
        this.state = { showModal: true, priority: props.reference?props.reference.referencePriority:0};
    }
    async componentWillReceiveProps(nextProps: any) {
        if(nextProps.reference!==this.props.reference) { 
            this.setState({priority: nextProps.reference.referencePriority})
        }
    }

    hideModal = () => {
        this.setState({
            showModal: false
        });
    }

    onPriorityChange = (event: any) => { 
        this.setState({ priority: event.target.value });
    }  

    showModal = () => {
        const { props: { id, modalID } } = this;
        if (modalID !== id) {
            return <div></div>;
        } 
        return (
            <Modal className="confirmation-modal" show={this.state.showModal} onHide={() => hideModal()}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: 'white' }} >Change Priority</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={4} xs={4}>
                            <label>Priority: </label>
                        </Col>
                        <Col sm={6} xs={6}>
                            <div className=" ">
                                <input className="reference-priority-input"
                                    id="priority"
                                    type="number"
                                    value={+this.state.priority}
                                    onChange={this.onPriorityChange}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="modal-buttons row">
                        <Button style={{
                            border: 'none',
                            backgroundColor: '#26a65b'
                        }}
                            onClick={() => this.props.reference.referenceId && updateReference(this.props.reference.referenceId, this.state.priority)}
                        >
                            Submit
                        </Button>
                        <Button style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white'
                        }}
                            onClick={() => hideModal()}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        return this.showModal();
    }
} 

export function mapStateToProps(state: any) {
    return {
        modalID: state.modal.get('modalID'),
        showModal: state.modal.get('showModal'),
        modalHeading: state.modal.get('modalHeading'),
        reference: state.modal.get('instance')
    };
}

export const ChangePriorityModal = withRouter(connect< IChangePriorityModalProps, any, any>(mapStateToProps)
    (ChangePriorityModalImpl));
