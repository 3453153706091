import { format } from 'date-fns';
import { setSuccess } from '../actions/loadingActions';
import { hideDeleteConcernModal } from '../actions/modalActions';
import { saveConcernTypes, saveOIFormSummary } from '../actions/summaryActions';
import { FORM_SUBMISSION_ERROR } from '../constants/general'; 
import { IAnswer, IForms, IHistory } from '../interfaces';
import { OIModel } from '../model/OIModel';
import { UserModel } from '../model/UserModel';
import { Answers } from '../utils/Answers';
import { showAlert} from '../utils/generalUtils';
import { get, getCustom, post, postCustom, putCustom } from '../utils/HTTP';  
import { getNotifications } from './profileService';
import { saveListCount } from '../actions/miscellaneousActions'; 

//export async function getAllOIForms(pageNumber = 1, freq: string = 'summary', year: string = 'na', month: string = 'na') {
export async function getAllOIForms(urlData?: any, forms?: IForms) { 
    try {
        //const response = await getCustom(`/SubmitForm/oiForm/${company}/${freq}/${year}/${month}/${pageNumber}`);
        let pageNumber =  urlData.pageNumber || 1;
        let sortIndexField =  urlData.sortIndexField || '';
        let sortingOrder =  urlData.sortingOrder || '';
        let searchItem =  urlData.searchItem || '';
        let axiosPreviousToken= urlData.axiosPreviousToken || ''; 
        let data = { ...forms?.commonFilterForm, formType: 'oiForm', pageNumber, sortIndexField, sortingOrder, searchItem }; 
        const response = await post('/GetForms', data, axiosPreviousToken);  
        if (response.data.status === 'valid') {
            OIModel.deleteAll();
            response
                .data
                .data
                .forEach((oiFormData: any) => {
                    const oiFormInstance: any = marshallOIFormInstance(oiFormData); 
                    new OIModel(oiFormInstance).$save();
                });
            saveListCount(response.data.TotalPages);
        }
        return response.data;
    } catch (error) {
        throw error;
    }
}

export function marshallOIFormInstance(data: { id: any; key: any; totalClosedFollowUp: number; totalFollowUp: number; totalOpenFollowUp: number; answers: any; date: number | Date; time: any; userId: any; }) {
    data.id = data.key;   
    data.totalClosedFollowUp = data.totalFollowUp - data.totalOpenFollowUp; 
    const answerData = new Answers(data.answers);  
    const obj = {
        dateReported: format(new Date(data.date), 'MM/dd/yyyy'),
        timeReported: data.time,
        dateOfIncident: format(new Date(answerData.get(1)), 'MM/dd/yyyy'),
        timeOfIncident: answerData.get(2),
        location: answerData.get(3),
        specifiedLocation: answerData.get(4),
        observerPosition: answerData.get(5), 
        observationType: answerData.get(6),
        concernType: answerData.get(7),
        activity: answerData.get(8),
        nearMiss: answerData.get(9),
        observation: answerData.get(10),
        consequence: answerData.get(11),
        actionTaken: answerData.get(12),
        observer: data.userId 
    };
    Object.assign(data, obj);
    delete data.answers;
    return data;
} 
export async function submitOIForm(userId: string, date: string, answers: IAnswer[],
    history: IHistory, groupName: string, projectName: string, forms: IForms, editId?: string) {  
    const submittedValues: any = {
        groupName,
        userId,
        formId: 'oiForm',
        date: editId ? answers[0].answer : format(new Date(), 'yyyy-MM-dd'),
        time: editId ? answers[1].answer : format(new Date(), 'HH:mm'),
        answers,
        projectName,
        associatedFormId: '',
        followUp: '0',
        deletedImages: [],
        deletedVideos: [],
        editId: '',
        /* imageUrls: [],
        videoUrls: [],
        fileData: [ ...forms.oiForm.oiImages || [], ...forms.oiForm.oiVideos || []]  */
        imageData: forms.oiForm.oiImages || [],
        videoData: forms.oiForm.oiVideos || []
    }; 

    try { 
        /* const imageURLs:Array<any> = [];
            let count = 0;
            const videoURLs:Array<any> = [];
            let videoCount = 0;
        if(forms.oiForm.oiImages) {
            for (let i = 0; i < forms.oiForm.oiImages.length; i++) {
                const file = await base64ToFile(forms.oiForm.oiImages[i].file, forms.oiForm.oiImages[i].name)
                    .then(file => {
                        return file;
                    }); 
                fileData.push(file);
                try {
                    const fileUpload = await uploadFile(file, forms.oiForm.oiImages[i].name.split('.')[0], 'fotos');
                    if (fileUpload) {
                        imageURLs.push(`${S3_URL}/fotos/${forms.oiForm.oiImages[i].name}`);
                        count++;
                    }
                } catch (error) {
                    throw Error;
                }
            }
        }
        if(forms.oiForm.oiVideos) {
            for (let i = 0; i < forms.oiForm.oiVideos.length; i++) {
                const file = await base64ToFile(forms.oiForm.oiVideos[i].file, forms.oiForm.oiVideos[i].name)
                    .then(file => {
                        return file;
                    });
                fileData.push(file);
                try {
                    const fileUpload = await uploadFile(file, forms.oiForm.oiVideos[i].name.split('.')[0], 'vdos'); 
                    if (fileUpload) {
                        videoURLs.push(`${S3_URL}/vdos/${forms.oiForm.oiVideos[i].name}`);
                        videoCount++;
                    }
                } catch (error) {
                    throw Error; 
                }
            } 
        }   */
        //if ( (!forms.oiForm.oiImages || (count === forms.oiForm.oiImages.length) ) && (!forms.oiForm.oiVideos || videoCount === forms.oiForm.oiVideos.length)) {
            /* submittedValues.imageUrls = imageURLs;
            submittedValues.videoUrls = videoURLs; */
            submittedValues.deletedImages = forms.oiForm.deletedImages || [];
            submittedValues.deletedVideos = forms.oiForm.deletedVideos || [];
            submittedValues.editId = editId;  
            const response = submittedValues.editId ? await post('/EditForm', submittedValues) : await post('/SubmitForm', submittedValues);
            if (JSON.stringify(response.data).indexOf('error') > 0) {
                showAlert(FORM_SUBMISSION_ERROR, 'oi-form', 'danger');
                return;
            } 
            showAlert(response.data.message, 'oi-modal', 'success', async () => {
                history.push('/home'); 
                /* await getAllOIForms();
                await getOIFormSummaryData(); */
            });
            return response;
        //} 
    } catch (error) {
        showAlert(FORM_SUBMISSION_ERROR, 'oi-form', 'danger');
        throw error;
    } 
} 

//export async function getOIFormSummaryData(freq: string = 'summary', year: string = 'na', month: string = 'na') {
export async function getOIFormSummaryData(forms?: IForms ) { 
    try { 
        const data = { teams: forms?.commonFilterForm.teams || '', groups: forms?.commonFilterForm.groups || '', fetchType: forms?.commonFilterForm.filterType || 'summary', year: forms?.commonFilterForm.year || '', month: forms?.commonFilterForm.month || '',reportStatus: forms?.commonFilterForm.reportStatus || 'active' }; 
        const response = await post('/GetOiReportSummary', data); 

        //const response = await getCustom(`/GetOiReportSummary/${company}/${freq}/${year}/${month}`);  
        /* response.data.data.concernTypes.Health = 15;
        response.data.data.concernTypes.Flow = 2; 
        response.data.data.concernTypes.System = 12; 
        response.data.data.concernTypes.Margin = 6;  */
        /* const concernTypes = { Safety: 0, Health: 0, Environment: 0, Security: 0, Other: 0 };
        concernTypes.Safety = response.data.data.concernTypes.Safety;
        concernTypes.Health = response.data.data.concernTypes.Health;
        concernTypes.Security = response.data.data.concernTypes.Security;
        concernTypes.Environment = response.data.data.concernTypes.Environment;
        concernTypes.Other = response.data.data.concernTypes.Other; 
        delete response.data.data[('concernTypes' || '')];
        response.data.data.concernTypes = concernTypes;*/ 
        saveOIFormSummary(response.data.data);
        return;
    } catch (error) {
        throw error;
    }
}

/* export async function applyMonthlyFilter(month: string | undefined, year: string | undefined) {
    if (!month) {
        setSuccess('ReportsPage');
        showAlert('Please select month.', 'oi-report-summary', 'danger');
        return;
    } else if (!year) {
        setSuccess('ReportsPage');
        showAlert('Please select year.', 'oi-report-summary', 'danger');
        return;
    }
    //await getOIFormSummaryData('monthly', year, month);
    setSuccess('ReportsPage');
}

export async function applyYearlyFilter(year: string | undefined) {
    if (!year) {
        setSuccess('ReportsPage');
        showAlert('Please select year.', 'oi-report-summary', 'danger');
        return;
    }
    //await getOIFormSummaryData('yearly', year);
    setSuccess('ReportsPage');
} */

export async function deleteConcernType(uniqueId: string, userId: string, company: string) {
    try {
        const response = await putCustom('/ConcernTypes', {
            uniqueId,
            active: false,
            userId
        });
        await getConcernTypes();
        await getNotifications(userId, company);
        setSuccess('delete-concern');
        hideDeleteConcernModal();
        showAlert('Concern Type Successfully deleted.', 'oi-form-success', 'success-alert');
        return response;
    } catch (error) {
        showAlert('Unable to delete concern type. Please try again later.', 'oi-form-failed', 'danger');
        throw error;
    }
}

export async function addNewConcern(concern: string, company: string,
    userUID: string, userId: string, activities: string[], resetTable: Function) {
    activities.push('Other');
    const requestBody = {
        concernTypes: {
            userId: userUID,
            company,
            concern,
            active: true,
            activities
        }
    };
    try {
        const response = await postCustom(`/ConcernTypes`, requestBody);
        await getConcernTypes();
        await getNotifications(userUID, company);
        resetTable();
        hideDeleteConcernModal();
        showAlert(response.data.message, 'oi-form-success', 'success-alert');
        return response;
    } catch (error) {
        showAlert('Could not add concern type. Please try again later.', 'add-concern-failed', 'danger');
        throw error;
    }
}

export async function getConcernTypes() { 
    try {
        const response = await get(`/ConcernTypes`);
        const concerns: any[] = [];
        /* let hasOther = false;
        let other = {};
        let othersIndex = 0; */
        response
            .data
            .data
            .map((concernData: { concern: string; }, index: number) => {
                /* if (concernData.concern === 'Other') {
                    Object.assign(other, concernData);
                    hasOther = true;
                    othersIndex = index;
                } */
                concerns.push(concernData);
            });
        /* if (hasOther) {
            concerns.splice(othersIndex, 1);
            concerns[concerns.length] = other;
        } */
        // if (UserModel.isAdmin()) {
        //     concerns[concerns.length] = {
        //         concern: 'Add New Concern Type',
        //         active: 1
        //     };
        // }
        saveConcernTypes(concerns);
    } catch (error) {
        throw error;
    }
}

export async function getFilteredOIFormList(filterBy: string, filterType: string, year: string, month: string) {
    /* const nearmiss = OIModel.getFiltered('nearmiss');
    const positive = OIModel.getFiltered('positive');
    const negative = OIModel.getFiltered('negative');
    OIModel.deleteAllFiltered(nearmiss, 'nearmiss');
    OIModel.deleteAllFiltered(positive, 'positive');
    OIModel.deleteAllFiltered(negative, 'negative'); */
    OIModel.deleteAll();
    try {
        const response = await getCustom(`/FilteredOIForms/${UserModel.getCompanyName()}/${filterType}/${year}/${month}/${filterBy}`);
        (response.data.data || []).forEach((instance: any) => {
            const oiInstance: any = marshallOIFormInstance(instance);
            oiInstance.filterBy = filterBy;
            //new OIModel(oiInstance).$saveFiltered(filterBy);
            new OIModel(oiInstance).$save();
        });
    } catch (error) {
        throw error;
    }
}
