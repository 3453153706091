import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom';
//import SignaturePad from 'react-signature-pad'; 
import { setSuccess } from '../../actions/loadingActions';
import { ISelectOptions, IHistory } from '../../interfaces';
import { GroupModel } from '../../model/GroupModel';
import { ProjectModel } from '../../model/ProjectModel';
import { IUserModelProps, UserModel } from '../../model/UserModel';
import { getAllGroups, getFilteredGroups } from '../../services/groupService'; 
import { dispatch } from '../../utils/generalUtils';
import { Async } from '../reusableComponents/Async';
import { ErrorMessageLabel } from '../reusableComponents/ErrorMessageLabel/ErrorMessageLabel';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { Form } from '../reusableComponents/FormComponents/Form';
import { Label } from '../reusableComponents/FormComponents/Label';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../reusableComponents/Loader';
import { ManualSignature } from '../reusableComponents/ManuallyGenerateSignature/ManualSignature';
import {getUser} from '../../services/userService';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';  
import { getAllProjects } from '../../services/projectService';
import { LocationInput } from '../reusableComponents/FormComponents/LocationInput';
import { MapInputModal } from '../reusableComponents/FormComponents/MapInputModal';
var SignaturePad = require('react-signature-pad');
export interface IUserFormImplProps {
    id?: string;
    userInstance: UserModel;
    hideFields?: string[];
    groupNames?: string[];
    handleFormSubmit?: (value: any, signatureBase64: any) => void;
    projectList?: ProjectModel[];
    buttonName?: string;
    currentUserId?: string;
    currentUserUID?: string;
    userId?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    email?: string;
    jobTitle?: string;
    jobDesc?: string;
    project?: string;
    group?: string;
    accessLevel?: string;
    phoneNumber?: string;
    locationName?: string;
    onClick?: Function;
    match?: {
        params: {
            id: string;
        }
    };
    history?: IHistory;
    company: string;
}

export interface IUserFormImplState {
    projectId: string;
    projectName: string;
    groupName: string;
    isDisabled: boolean;
    signature: File | string | undefined;
    signatureURL: string;
    isSignatureUploaded: boolean;
    signNew: boolean;
    isAutoGenerate: boolean;
    popOver: boolean;
}
export class UserFormImpl extends React.PureComponent<IUserFormImplProps, IUserFormImplState> {

    constructor(props: IUserFormImplProps) {
        super(props);
        this.state = {
            projectId: '', projectName: '', groupName: '', signNew: false,
            isDisabled: true, signature: props.userInstance ? props.userInstance.props.signatureUrl : '',
            signatureURL: '', isSignatureUploaded: false, isAutoGenerate: false, popOver: false
        };
    } 

    project = '';
    group = '';
    accessLevel: any = '';
    
    componentWillMount() {
        const { userInstance, accessLevel } = this.props;
        if (!userInstance) {
            return;
        }
        this.setState({
            isDisabled: false
        });
        this.setSuccessForGroups();
        if (userInstance.props.projects && userInstance.props.projects.length > 0) {
            this.project = userInstance.props.projects[0];
            //getFilteredGroups(this.project ); 
            getFilteredGroups(userInstance.props.projects.join(','),'projectName');
        }
        if (userInstance.props.groups && userInstance.props.groups.length > 0) {
            this.group = userInstance.props.groups[0];

        }
        this.accessLevel = accessLevel;
    }

    refs!: {
        acceptorSignature: typeof SignaturePad;
    };
    promise = async () => {
        const { currentUserUID } = this.props;
        if (currentUserUID) {
            await getUser(currentUserUID);
        } 
        await getAllProjects(); 
        await getAllGroups();

        /* if (UserModel.list().length <= 1) {
            getAllUsers();
        }
        */
        return null;
    }

    static childContextTypes = {
        formModel: PropTypes.string
    };

    getChildContext() {
        return { formModel: 'forms.userForm' };
    }

    projectIdentifier: string = 'projects';
    groupIdentifier: string = 'groups';
    saveSignature = (file: any, acceptorSignature: any) => { 
        // const acceptorSignature = this
        //     .refs
        //     .acceptorSignature
        //     .toDataURL();
        // const file = await base64ToFile(acceptorSignature, 'user-signature.png').then(function (file) {
        //     return file;
        // });
        this.setState({
            isSignatureUploaded: true,
            signature: file,
            signatureURL: acceptorSignature,
            signNew: false
        });
    }

    saveGeneratedSignature = (file: any, base64: any) => {
        // const file = await base64ToFile(base64, 'user-signature.png').then(function (file) {
        //     return file;
        // });
        this.setState({
            isSignatureUploaded: true,
            signature: file,
            signatureURL: base64,
            signNew: false
        });
    }
    setSuccessForGroups = () => {
        setSuccess(this.groupIdentifier);
    }
    setSuccessForProjects = () => {
        setSuccess(this.projectIdentifier);
    }

    handleProjectSelect = (projectId: string) => {
        /* if (!projectId) {
            return;
        } */
        getFilteredGroups(projectId);
        //dispatch(actions.change('forms.userForm.projects', projectId));
        this.setState({
            isDisabled: false
        });
    }

    handleGroupSelect = (groupId: string) => {
        /* const groupInstance = GroupModel.getBy('groupName', groupId);
        if (!groupInstance) {
            return;
        } */
        //dispatch(actions.change('forms.userForm.groups', groupInstance.props.groupName));
    }

    handleAccessLevel = (value: any) => {
        dispatch(actions.change('forms.userForm.accessLevel', value));
    }

    getProjectList = () => {
        const { projectList } = this.props;
        if (!projectList) {
            return;
        }
        const menuItems = projectList.map(
            (projectInstance) => ({
                label: (projectInstance.props.projectName || 'NA'),
                value: (projectInstance.props.projectId || 'NA'),
            })
        );
        return menuItems;
    }

    getAccessLevelOptions = () => {
        const { currentUserId } = this.props;
        const userInstance = UserModel.get(currentUserId || '');
        let userLevels: { label: string; value: string; }[] = []; 
        switch(userInstance && userInstance.props.accessLevel) { 
            case 'L1':
                userLevels = [{ label: 'L1', value: 'L1' }, { label: 'L2', value: 'L2' }, { label: 'L3', value: 'L3' }, { label: 'L4', value: 'L4' }, { label: 'L5', value: 'L5' } ]
                break;
            case 'L2':
                userLevels = [ { label: 'L2', value: 'L2' }, { label: 'L3', value: 'L3' }, { label: 'L4', value: 'L4' },{ label: 'L5', value: 'L5' } ]
                break;
            case 'L3':
                userLevels = [ { label: 'L3', value: 'L3' }, { label: 'L4', value: 'L4' },{ label: 'L5', value: 'L5' } ]
                break;
            case 'L4':
                userLevels = [ { label: 'L4', value: 'L4' },{ label: 'L5', value: 'L5' }]
                break;
            case 'L5':
                userLevels = [ { label: 'L5', value: 'L5' }]
                break; 

        }
        return userLevels; 
    }

    getGroupList = () => {
        let groupList: ISelectOptions[] = [];
        const { groupNames, userInstance } = this.props; 
        if (userInstance) {
            userInstance.props.groups || [].forEach(groupName => {
                const groupId = GroupModel.getIdByName(groupName);
                groupList.push({ label: groupName, value: groupId });
            });
        }  
        if (!groupNames) { 
            return groupList;
        } 
        groupList = [];
        groupNames.forEach((groupName) => {
            const groupId = GroupModel.getIdByName(groupName);
            groupList.push({ label: groupName, value: groupId });
        });  
        return groupList;
    }

    getDisplayStyle = (hideField: string) => {
        const { hideFields } = this.props;
        return hideFields && hideFields.indexOf(hideField) > -1 ? { display: 'none' } : { display: 'block__' };
    }

    handleFormSubmit = (values: IUserModelProps) => { 
        const { userInstance } = this.props;
        if(userInstance) {
            values = Object.assign({originalEmail: userInstance.props.email }, values); 
            if(!this.state.signatureURL) { 
                values = Object.assign({signatureUrl: userInstance.props.signatureUrl }, values);
            }
        } 
        return this.props.handleFormSubmit && this.props.handleFormSubmit(values, this.state.signatureURL);
    }
    handleSomething = (e: { target: { value: string; }; }) => { 
        //eslint-disable-next-line
        const strongRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
        if (e.target.value.length <= 0) {
            this.setState({ popOver: false });
            return;
        }
        if (!strongRegex.test(e.target.value)) {
            this.setState({ popOver: true });
            return;
        }
        this.setState({ popOver: false }); 
    }
    
    renderField = (label: string | undefined, placeholder: any, ref: string, type: any, hide: React.CSSProperties | undefined, required: any, defaultValue?: any) => {
        const editEnabledFields = ['phoneNumber','jobTitle','jobDesc'];
        return (
            
            <Row className="show-grid" style={hide}>
                <Label required={label === 'Phone Number' ||
                    label === 'Middle Name' ? false : true} htmlFor={label}>{label + ':'}</Label>
                <Col sm={6} className="input">
                    <RRFInput
                        id={label}
                        type={type ? type : 'text'}
                        placeholder={placeholder}
                        model={`.${ref}`}
                        defaultValue={defaultValue}  
                        isDisabled={this.props.id && this.props.id==='UpdateProfile'?(editEnabledFields.indexOf(ref)>-1 ? false : true) : false }
                    />
                </Col>
                
            </Row >
            
        );
    }

    renderPasswordField = (hide: React.CSSProperties) => {   
        if (hide.display === 'none') {
            return;
        }
        return(<React.Fragment>
        <Row className="show-grid" style={hide}>
            
            <Label required htmlFor="password">Password:</Label>
            <Col sm={6} className="input">
                    <RRFInput
                        id="password"
                        type="password"
                        placeholder="Enter your password..."
                        model=".password"
                        onChange={(e: any) => this.handleSomething(e)}
                    />
                {/* {this.state.popOver && <h5 style={passwordLabel}>*Your Password is not strong</h5>}
                  */}
                <ErrorMessageLabel isActive={this.state.popOver} 
                errorMessage="* Password should have atleast one Uppercase Letter, 
                one Lowercase character , one numeric character, 
                one special symbol and minimum length of password should be 8 character"/>
            </Col>      
            
        </Row>
        
        </React.Fragment>);
    }

    renderSignatureField = () => {
        if (this.props.userInstance) {
            return <>{/* <Row className="show-grid" style={{ paddingBottom: '20px' }} > */}
                <Label required={false} htmlFor="files">Signature:</Label>
                <Col sm={6} style={{ color: 'green' }}>
                    <img height="56px" style={{ border: '1px solid #d2d2d2', backgroundColor: 'white', width: '100%', borderRadius: '10px' }} alt="SafeConnect"
                        src={this.state.signatureURL || this.props.userInstance.props.signatureUrl} />
                    <div style={{textAlign: 'center'}}> <button 
                        className="button"
                        style={newSign}
                        onClick={() => this.setState({ signNew: true })}
                    >
                        Sign New
                  </button>
                    </div>
                </Col>
            {/* </ Row > */}</>;
        }

        return '';
    } 

    renderFormInput = () => {
        const { props: { userInstance, hideFields, userId, firstName, middleName, lastName,
            email, buttonName, jobTitle, jobDesc, accessLevel, project, group, phoneNumber, id, locationName },
            renderField, getDisplayStyle, handleAccessLevel, getAccessLevelOptions,
            setSuccessForProjects, getGroupList, renderPasswordField, state: { isDisabled }
        } = this;  
        return (
            <Form loader={<SubmissionLoader />} model="forms.userForm" onSubmit={this.handleFormSubmit}> 
                <MapInputModal id="user-form" />
                {id==='UpdateProfile' && <Col sm={12} style={{ color: 'red',textAlign: 'center', marginBottom: '20px' }} className="input ">(You can update Phone number, Job Title, Job Desc{`${(userInstance.props.accessLevel==='L1' || userInstance.props.accessLevel==='L2') ? ', Proj/Org, Grp/Div ':''}`} and Signature)</Col> }
                {renderField('User ID', 'Enter user id', 'userId', 'number',
                    getDisplayStyle('userId'), hideFields && hideFields.indexOf('userId') <= -1, userId)}

                {renderPasswordField(getDisplayStyle('password'))}

                {renderField('First Name', 'Enter first name...', 'firstName', 'text',
                    getDisplayStyle('firstName'), hideFields && hideFields.indexOf('firstName') <= -1, firstName)}

                {renderField('Middle Name', 'Enter middle name', 'middleName', 'text',
                    getDisplayStyle('middleName'), hideFields && hideFields.indexOf('middleName') <= -1, middleName)}

                {renderField('Last Name', 'Enter last name...', 'lastName', 'text',
                    getDisplayStyle('lastName'), hideFields && hideFields.indexOf('lastName') <= -1, lastName)}

                {renderField('Email', 'Enter email id...', 'email', 'email',
                    getDisplayStyle('email'), hideFields && hideFields.indexOf('email') <= -1, email)}

                {renderField('Phone Number', 'Enter your phone number...', 'phoneNumber', 'phoneNumber',
                    getDisplayStyle('phoneNumber'), hideFields && hideFields.indexOf('phoneNumber') <= -1, phoneNumber)}

                <Row className="show-grid">
                    <Label required={true} htmlFor="accessLevel">Access Level:</Label>
                    <Col sm={6} className="input">
                        <RRFInput
                            id="accessLevel"
                            type="dropdown"
                            placeholder="Select access level..."
                            model=".accessLevel"
                            defaultValue={accessLevel}
                            onSelect={handleAccessLevel}
                            menuItems={getAccessLevelOptions()}
                            isDisabled={this.props.id && this.props.id==='UpdateProfile' ? true : false }
                        />
                    </Col>
                </Row>
                { hideFields && hideFields.indexOf('locationName') <= -1 && <Row className="show-grid">
                    <Label htmlFor={'Location'}>{'Location:'}</Label>
                    <LocationInput  
                        companyName={this.props.company}
                        model=".specifiedLocation"
                        id="oi-form"
                        navigator={navigator}
                        defaultValue={locationName || ''}
                        sm={6}
                        disableLabel={true}
                    />
                </Row> }
                {renderField('Job Title', 'Enter job title...', 'jobTitle', 'text',
                    getDisplayStyle('jobTitle'), hideFields && hideFields.indexOf('jobTitle') <= -1, jobTitle)}

                {renderField('Job Description', 'Enter job description...', 'jobDesc', 'text',
                    getDisplayStyle('jobDesc'), hideFields && hideFields.indexOf('jobDesc') <= -1, jobDesc)}
                
                <Row className="show-grid">
                    <Label required={true}>Select Teams:</Label>
                    <Col sm={6} className="input" onFocus={setSuccessForProjects} >
                        {/* <Async
                            identifier={this.projectIdentifier}
                            promise={getAllProjects}
                            error={<div></div>}
                            loader={
                                <RRFInput
                                    type="dropdown"
                                    menuItems={[{ label: 'Loading...', value: '' }]}
                                    model=".projects"
                                />}
                            content={ */}<RRFInput
                                multi={true}
                                onSelect={this.handleProjectSelect}
                                type="dropdown"
                                menuItems={this.getProjectList()}
                                defaultValue={project}
                                model=".projects" 
                                isDisabled={this.props.id && this.props.id==='UpdateProfile' && ( userInstance.props.accessLevel!=='L1' && userInstance.props.accessLevel!=='L2' ) ? true : false }
                                searchable
                            />{/* }
                        /> */}
                    </Col>

                </Row>
                <Row className="show-grid">
                    <Label required={true}>Select Grp/Div:</Label>
                    <Col sm={6} className="input"  >
                       {/*  <Async
                            identifier={groupIdentifier}
                            promise={undefined}
                            error={<div></div>}
                            loader={
                                <RRFInput
                                    placeholder="Select project before selecting a group"
                                    isDisabled={isDisabled}
                                    type="dropdown"
                                    model=".groups"
                                />}
                            content={ */}
                                <RRFInput
                                    multi={true}
                                    defaultValue={group}
                                    isDisabled={this.props.id && this.props.id==='UpdateProfile' && ( userInstance.props.accessLevel!=='L1' && userInstance.props.accessLevel!=='L2' ) ? true : isDisabled}
                                    menuItems={getGroupList()}
                                    type="dropdown"
                                    model=".groups"
                                    onSelect={this.handleGroupSelect} 
                                    searchable
                                />{/* }
                        /> */}
                    </Col>
                </Row>
                <Row className="show-grid e-signature user-form">
                    {/* {this.props.hideFields.indexOf('signature') > -1 ? '' :
                        this.state.signNew ? this.renderSignatureInput() : this.renderSignatureField()} */}
                    {hideFields && hideFields.indexOf('signature') > -1 ? '' : 
                        this.state.signNew ? 
                        <React.Fragment>
                        <Label required={false} htmlFor="files">Signature:</Label>
                        <ManualSignature 
                            autoSignTitle={`${this.props.userInstance.props.firstName} 
                            ${this.props.userInstance.props.lastName}`}
                            saveSignature={this.saveSignature}
                            saveGeneratedSignature={this.saveGeneratedSignature}
                            className="show-grid"
                            autoGenerateProps ={ { className: 'signature-generator' } }
                            //userId={this.props.userId} 
                        /> 
                        </React.Fragment> : this.renderSignatureField() }
                </Row>
                <div style={{ marginTop: '50px' }} className="form-button"> 
                    <Button
                        style={{
                            color: '#5479AF',
                            border: '1px solid #5479AF',
                            backgroundColor: 'white'
                        }} 
                        onClick={() => this.props.history?.goBack() }
                        redirectTo="home" type="reset"
                    >
                        <i className="fa fa-ban" aria-hidden="true"></i>
                        &nbsp;
                        Cancel
                    </Button>
                    <Button
                        style={{
                            color: '#FFFFFF',
                            backgroundColor: '#26a65b'
                        }}
                    > {userInstance ? <i className="fa fa-refresh" aria-hidden="true"></i> :
                        <i className="fa fa-plus" aria-hidden="true"></i>}
                        &nbsp;
                        {buttonName}
                    </Button>
                </div>
            </Form >
        );
    }

    render() {
        return (
        <React.Fragment>
        
        <Async
            identifier="UserForm"
            promise={this.promise}
            error={<ErrorPage />}
            loader={<Loader />}
            content={this.renderFormInput()}
        />
        </React.Fragment>);
    }
}

export function forEachStateToProps(state: any, ownProps: { match: { params: { id: any; }; }; }) {
    const projectList = ProjectModel.list(state); 
    const groupNames = state.groups.get('groupNames');
    const userId = ownProps.match.params.id;
    const userInstance = UserModel.get(userId, state);
    const company = state.login.get('company'); 
    return { groupNames, projectList, userInstance, currentUserId: state.login.get('userId', state), currentUserUID: state.login.get('userUID', state), company,
    };
}
export const UserForm = withRouter(connect<IUserFormImplProps, any, any >(forEachStateToProps)(UserFormImpl));
  
const newSign: React.CSSProperties = {
    border: 'none',
    color: 'white',
    background: '#26a65b',
    marginTop: '10px',
    width: '93px',
    borderRadius: '5px',
};
 
