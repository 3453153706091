import '../../forms.scss';
import './oiForm.scss';

import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Container as Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { withRouter } from 'react-router-dom'; 

import { EMPTY_FORM_MESSAGE } from '../../constants/general';
import { IConcernType, IForms, IHistory, ISelectOptions } from '../../interfaces';
import { GroupModel } from '../../model/GroupModel';
import { IOIModelProps, OIModel } from '../../model/OIModel';
import { ProjectModel } from '../../model/ProjectModel';
import { UserModel } from '../../model/UserModel';
import { getAllGroups } from '../../services/groupService';
import { submitOIForm } from '../../services/oiformService';
import { getAllProjects } from '../../services/projectService'; 
import { constructAnswers, dispatch, isFormEmpty, showAlert } from '../../utils/generalUtils';
import { Alert } from '../Alert';
import { Async } from '../reusableComponents/Async';
import { BasePage } from '../reusableComponents/BasePage';
import { ConcernDropdown } from '../reusableComponents/ConcernTypesComponents/ConcernDropdown';
import { ProjectInput } from '../reusableComponents/FormComponents/ProjectInput';
import { GroupInput } from '../reusableComponents/FormComponents/GroupInput';
import { ErrorPage } from '../reusableComponents/ErrorPage';
import { Button } from '../reusableComponents/FormComponents/Button';
import { FileInput } from '../reusableComponents/FormComponents/FileInput';
import { VideoInput } from '../reusableComponents/FormComponents/VideoInput';
import { Form } from '../reusableComponents/FormComponents/Form';
import { Label } from '../reusableComponents/FormComponents/Label';
import { LocationInput } from '../reusableComponents/FormComponents/LocationInput';
import { MapInputModal } from '../reusableComponents/FormComponents/MapInputModal'; 
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import { Loader } from '../reusableComponents/Loader';
import { AlertModal } from '../reusableComponents/ModalComponents/AlertModal';
import { SubmissionLoader } from '../reusableComponents/SubmissionLoader';
import { Title } from '../reusableComponents/Title'; 
import { VoiceRecognition } from '../reusableComponents/VoiceRecognition/VoiceRecognition';
import { resetEmptyField } from '../../actions/miscellaneousActions'; 

export interface IOIFormProps {
    userInstance: UserModel;
    userUID: string;
    userId: string; 
    company: string;
    history?: IHistory;
    concernTypes: IConcernType[];
    forms: IForms;
    activityOptions: ISelectOptions[];  
    oiInstance?:OIModel;
    editId?: string;
    emptyFields?: any;  
}

export interface IOIFormState {
    currentDate: string;
    currentTime: string;
    observer?: string;
    isDisabled: boolean;
    isChecked: boolean;
    /**
     *  Once data from API fetches remove this and take them as props and get value from store
     */
    locationOptions: ISelectOptions[];
    longitude: number;
    latitude: number;  
} 


export class OIFormImpl extends React.PureComponent<IOIFormProps, IOIFormState> {

    /**
     * Setting the initial values for the component. Once the correct data from the server comes remove these
     * and fetch the data from the store.
     */
    
    constructor(props: IOIFormProps | Readonly<IOIFormProps>) {
        super(props);
        this.state = {
            currentDate: format(new Date(), 'yyyy-MM-dd'),
            locationOptions: [
                { label: 'SPI 1029', value: 'SPI 1029' },
                { label: 'SPI 1033', value: 'SPI 1033' },
                { label: 'BNSF', value: 'BNSF' },
                { label: '7 Eleven', value: '7 Eleven' }],
            isDisabled: true,
            isChecked: false,
            currentTime: format(new Date(), 'HH:mm'),
            longitude: 0,
            latitude: 0, 
        };  
    }

    promise = async () => {
        resetEmptyField(); 
        //await getUser(this.props.userUID);
        if (ProjectModel.list().length <= 0) {
            await getAllProjects();
        }
        if (GroupModel.list().length <= 0) {
            await getAllGroups();
        } 
        return;
    }
    componentWillMount() { 
        const { oiInstance } = this.props;
        if(oiInstance) { 
            if(oiInstance.props.nearMiss==='1')
                this.setState({ isDisabled: false }); 

        }
        dispatch(actions.change('forms.oiForm.changedConcernType', ''));
    }

    getChildContext() {
        return { formModel: 'forms.oiForm' };
    }

    static childContextTypes = {
        formModel: PropTypes.string
    }; 
    /**
     * To handle the dependency of Near miss on the type of observation.
     * Disables the near miss if the type of observation is positive observations.
     */
    handleObservationType = (value: string) => {
        if (value === 'Positive Observation') {
            this.setState({
                isDisabled: true,
            });
            dispatch(actions.change('forms.oiForm.nearMiss', false));
        } else {
            this.setState({
                isDisabled: false,
            });
        }
    }

    getLocation = (position: { coords: { latitude: number, longitude: number } }) => {
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        });
        return position;
    }
    /** 
     * Fired when form is submitted with the input values.
     * @param event The event object to prevent the reloading of the page after submission.
     */
    handleFormSubmit = ({ timeOfIncident, specifiedLocation, observerPosition, observationType, concernType, observer, nearMiss, consequence, actionTaken, observation, groupName, mobileLocation, locationName, projectName, dateOfIncident, activity }: IOIModelProps) => {  
        const { props: { userUID, forms, history, oiInstance, editId, emptyFields } } = this; 
        //const locationString = longitude.toString() + ', ' + latitude.toString();
        //mobileLocation =  oiInstance ? oiInstance.props.location : mobileLocation; 
        const answerData = {
            reportDate: dateOfIncident, reportTime: timeOfIncident,
            locationString: mobileLocation, specifiedLocation: locationName, observerPosition, observationType,
            concernType, activity, nearMiss, observation, consequence, actionTaken
        };
        const submittedValues = { dateOfIncident,timeOfIncident, locationName, projectName, groupName, observationType, concernType, activity, observation };  
        if( ( !editId && forms.oiForm.timeOfIncident>this.state.currentTime && forms.oiForm.dateOfIncident>=this.state.currentDate) )  {
            showAlert('Unable to save. Time of observation should not greater then current time.', 'oi-form-failed', 'danger');
            return;
        }
        if( (editId && oiInstance && forms.oiForm.timeOfIncident>oiInstance.props.timeReported && forms.oiForm.dateOfIncident>=format(new Date(oiInstance.props.dateReported),'yyyy-MM-dd') )  )  {
            showAlert('Unable to save. Time of observation should not greater then current time.', 'oi-form-failed', 'danger');
            return;
        }
        if (isFormEmpty(submittedValues, [], emptyFields)) {
            showAlert(EMPTY_FORM_MESSAGE, 'oi-form-failed', 'danger');
            return;
        }
        resetEmptyField()
        let answers = constructAnswers(answerData); 
        return history && submitOIForm(userUID, dateOfIncident, answers, history, groupName || '', projectName || '', forms, editId); 
    } 
    renderContent = () => {
        const { oiInstance, editId, history, forms, } = this.props;
        const { handleFormSubmit, handleObservationType, 
            state: { currentDate, currentTime, isDisabled },
            props: { activityOptions } } = this;   
        if(editId && oiInstance===undefined ) {
            history?.push('/oi-reports');
        }   
        if(oiInstance && oiInstance.props.observationType==='Negative Observation' ) {
            this.setState({ isDisabled: false, });
        }   

        return (
            <Grid className="oi-form" style={{ width: '100%' }}>
                <MapInputModal id="oi-form" />
                {/* <MicrophoneModal id="oi-form" /> */}
                <Form loader={<SubmissionLoader />} model="forms.oiForm" onSubmit={handleFormSubmit}>
                    <Title titleColor="yellow" text="O&amp;I Form" noNeedBorder={true}/>
                    <AlertModal id="oi-modal" />
                    {/* <Alert className="success-alert" id="oi-form-success" /> */}
                    <fieldset>
                        <legend>Observation &amp; Intervention</legend>
                        <Row className="show-grid" >
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="currentDate">Date of Observation:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="currentDate"
                                        type="date"
                                        defaultValue={oiInstance? format(new Date(oiInstance.props.dateOfIncident), 'yyyy-MM-dd'): currentDate}
                                        model=".dateOfIncident"
                                        maxdate={oiInstance? format(new Date(oiInstance.props.dateReported), 'yyyy-MM-dd'):currentDate} 
                                    />
                                </Col>
                            </Col>
                            <Col sm={4} xs={12}>
                                <Label sm={12} htmlFor="currentTime">Time of Observation:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="currentTime"
                                        defaultValue={oiInstance ? oiInstance.props.timeOfIncident : currentTime}
                                        type="time"
                                        model=".timeOfIncident" 
                                    />
                                </Col>
                            </Col>
                            <LocationInput  
                                companyName={this.props.company}
                                model=".specifiedLocation"
                                id="oi-form"
                                navigator={navigator}
                                defaultValue={(oiInstance && oiInstance.props.specifiedLocation)  }
                            />
                        </Row>
                        <Row className="show-grid">
                            <Col sm={3} xs={12}>
                                <ProjectInput model=".projectName" id="project" defaultValue={oiInstance ? oiInstance.props.projectName : this.props.userInstance && this.props.userInstance.props.projects && this.props.userInstance.props.projects[0]} />
                            </Col>
                            <Col sm={3} xs={12}>
                                <GroupInput model=".groupName" id="group" defaultValue={oiInstance ? oiInstance.props.groupName : this.props.userInstance && this.props.userInstance.props.groups && this.props.userInstance.props.groups[0]} />
                            </Col> 
                            <Col sm={3} xs={12}>
                                <Label sm={12}>Observer:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="observer"
                                        type="text"
                                        defaultValue={this.props.userInstance && this.props.userInstance.props.firstName}
                                        model=".observer"
                                        isDisabled={true}
                                    />
                                </Col>
                            </Col>
                            <Col sm={3} xs={12}>
                                <Label sm={12} htmlFor="observerPosition">Observer's Position:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="observerPosition"
                                        type="text"
                                        placeholder="Enter observer's position or project role..."
                                        model=".observerPosition"
                                        defaultValue={oiInstance ? oiInstance.props.observerPosition : this.props.userInstance && this.props.userInstance.props.jobTitle} 
                                        isDisabled={true}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="observationType">Observation Type:</Label>
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="observationType"
                                        onSelect={handleObservationType}
                                        model=".observationType"
                                        type="dropdown"
                                        defaultValue={oiInstance && oiInstance.props.observationType }
                                        menuItems={[{ label: 'Positive Observation', value: 'Positive Observation' }, { label: 'Negative Observation', value: 'Negative Observation' }]}
                                    />
                                </Col>
                            </Col>
                            <Col sm={4} xs={12}>
                                <ConcernDropdown model=".concernType" id="concernType" defaultValue={oiInstance && oiInstance.props.concernType} />
                            </Col>
                            <Col sm={4} xs={12}>
                                <Label required sm={12} htmlFor="activity">Activity:</Label>
                                <Col sm={12} className="input">
                                    {forms.oiForm.changedConcernType==='Other' || ( forms.oiForm.concernType==='Other' && ( (oiInstance && oiInstance.props.concernType === 'Other') || !oiInstance) ) ?<RRFInput
                                        id="activity"
                                        type="text"
                                        defaultValue={oiInstance && oiInstance.props.activity}
                                        model=".activity" 
                                    />:<RRFInput
                                        id="activity"
                                        model=".activity"
                                        noResultsText="Please select concern type..."
                                        type="dropdown"
                                        menuItems={activityOptions}
                                        defaultValue={oiInstance && oiInstance.props.activity}
                                    /> }
                                </Col>
                            </Col>
                        </Row>
                        <Row className="single-checkbox">
                            <Col sm={12} xs={12}>
                                <label className="checkbox-label" htmlFor="nearMiss">Near Miss:</label>
                                <RRFInput
                                    id="nearMiss"
                                    model=".nearMiss"
                                    type="checkbox"
                                    isDisabled={ isDisabled }
                                    defaultValue={oiInstance && oiInstance.props.nearMiss==='1'?true:null}
                                     
                                />
                                <label htmlFor="nearMiss"></label>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={4} xs={12}> 
                                <Label required sm={12} htmlFor="observation">Observation:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.oiForm.observation} model="forms.oiForm.observation" />
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="observation"
                                        placeholder="Enter observation..."
                                        type="textarea"
                                        model=".observation"
                                        className="non-resizable"
                                        defaultValue={oiInstance && oiInstance.props.observation}
                                    />
                                </Col>
                            </Col>
                            <Col sm={4} xs={12}>
                                <Label sm={12} htmlFor="consequence">Consequence:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.oiForm.consequence} model="forms.oiForm.consequence" /> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="consequences"
                                        placeholder="Enter consequence..."
                                        type="textarea"
                                        model=".consequence"
                                        className="non-resizable"
                                        defaultValue={oiInstance && oiInstance.props.consequence}
                                    />
                                </Col>
                            </Col>
                            <Col sm={4} xs={12}> 
                                <Label sm={12} htmlFor="actionTaken">Action Taken or Suggested:</Label>
                                <VoiceRecognition labelText="" defaultValue={forms.oiForm.actionTaken} model="forms.oiForm.actionTaken" /> 
                                <Col sm={12} className="input">
                                    <RRFInput
                                        id="actionTaken"
                                        placeholder="Enter action taken or suggested..."
                                        type="textarea"
                                        model=".actionTaken"
                                        className="non-resizable"
                                        defaultValue={oiInstance && oiInstance.props.actionTaken}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        
                        <Row className="show-grid">
                            <Col sm={6} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-images">Images:</Label>
                                <Col sm={12} className="input">
                                    <FileInput
                                        model=".oiImages"
                                        multiple={true}
                                        id="upload-images"
                                        defaultValue={oiInstance && oiInstance.props.imageUrls}
                                    /> 
                                </Col>
                            </Col> 
                            <Col sm={6} xs={12}>
                                <Label required={false} sm={12} htmlFor="upload-videos">Videos:</Label>
                                <Col sm={12} className="input">
                                    <VideoInput
                                        model=".oiVideos"
                                        multiple={true}
                                        id="upload-videos"
                                        defaultValue={oiInstance && oiInstance.props.videoUrls}
                                    /> 
                                </Col>
                            </Col> 
                        </Row>
                    </fieldset>
                    <Row className="show-grid"> 
                        <div className="form-button" style={{ marginTop: '20px' }}>
                            <Alert className="danger-alert" id="oi-form-failed" />
                            <Button style={{
                                color: 'rgb(84, 121, 175)',
                                border: '1px solid #5479AF',
                                backgroundColor: 'white',
                            }}
                                onClick={() => this.props.history?.goBack() }
                                redirectTo="goBack" type="button"
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                                &nbsp;
                                    Cancel
                            </Button>
                            <Button style={{
                                color: '#FFFFFF',
                                border: 'none',
                                backgroundColor: '#26A65B'
                            }}
                            >  <i className={ oiInstance?"fa fa-refresh":"fa fa-plus" }  aria-hidden="true"></i>
                            &nbsp;
                            { oiInstance ? 'Update' : 'Submit' }
                        </Button>
                        </div>
                    </Row>
                </Form>
            </Grid>
        );
    } 
 
    render() {
        return (
            <BasePage className="safeconnect-form">
                {/* <ConfirmationModal id="concern-types" /> */} 
                <Async
                    promise={this.promise}
                    identifier="OIForm"
                    loader={<Loader />}
                    error={<ErrorPage />}
                    content={this.renderContent()}
                />
            </BasePage >
        );
    }
}

export function mapStateToProps(state: any,ownProps: any) { 
    const userId = state.login.get('userId'); 
    const userUID = state.login.get('userUID');
    const company = state.login.get('company'); 
    const userInstance = UserModel.get(userId);
    const oiInstance = OIModel.get(ownProps.match.params.id); 
    const editId = ownProps.match.params.id;  
    return {
        userId, userInstance, 
        userUID, company,
        forms: state.forms,
        activityOptions: state.formSummary.get('activityMenuItems'),
        concernTypes: state.formSummary.get('concernTypes'), oiInstance, editId, 
        emptyFields: state.miscellaneous.get('fieldModelName') || [],  
    };
} 
export const OIForm = withRouter(connect<IOIFormProps, any, any>(mapStateToProps)(OIFormImpl));
